import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router-dom';
import { AddAppPage, ChooseTemplatePage } from '@modules';
import { DashboardStories, GroupsType, StoriesGroup, useFetchApps } from '@features';
import { RootState } from '@store';
import { useSelector } from 'react-redux';
import { StartStep } from '@modules/DashboardModule/types';
import { AddGroupPage } from '@modules/DashboardModule/pages/AddGroupPage';
import { Header } from '@components';
import { useAuth } from '@hooks';
import { StartStepsHeader } from '../StartStepsHeader';

import './StartUseLayout.scss';

const b = block('StartUseLayout');

export const StartUseLayout: React.FC = () => {
  const { appId } = useParams<{ appId: string }>();
  const appsState = useSelector((store: RootState) => store.appManager);
  const currentGroupId = useSelector((store: RootState) => store.groups.current);
  const currentOnboardingId = useSelector((store: RootState) => store.onboardings.current);
  const [currentTemplateId, setCurrentTemplateId] = useState<string>();
  const [completedSteps, setCompletedSteps] = useState<StartStep[]>([]);

  useAuth();
  useFetchApps(!appId);

  const [step, setStep] = useState<StartStep>(StartStep.APP);
  const [currentGroup, setCurrentGroup] = useState<StoriesGroup>();

  const groups = useSelector((store: RootState) => store.groups);
  const onboardings = useSelector((store: RootState) => store.onboardings);

  useEffect(() => {
    if (currentGroupId) {
      setCurrentGroup(groups.groups.find((group) => group.id === currentGroupId));
    } else if (currentOnboardingId) {
      setCurrentGroup(onboardings.onboardings.find((group) => group.id === currentOnboardingId));
    }
  }, [currentGroupId, currentOnboardingId]);

  useEffect(() => {
    if (appsState.current && (!currentGroupId || !currentOnboardingId)) {
      setCompletedSteps([StartStep.APP]);
      setStep(StartStep.GROUP);
    }
  }, [appsState.current]);

  if (appsState.status !== 'loaded') {
    return (
      <div className={b({ noNav: true })}>
        <Header className={`${b('header')}`} />

        <div className={b('loadContainer')}>
          <DashboardStories isLoading type={GroupsType.GROUP} />
        </div>
      </div>
    );
  }

  const handleGroupCreated = () => {
    setCompletedSteps([StartStep.APP, StartStep.GROUP]);
    setStep(StartStep.STORIES);
  };

  const handleSelectTemplate = (templateId: string) => {
    setCurrentTemplateId(templateId);
  };

  return (
    <div className={b({ noNav: true })}>
      <Header
        centerNav={<StartStepsHeader completedSteps={completedSteps} currentStep={step} />}
        className={`${b('header')}`}
        noSubscriptionStatus
        withLogo={false}
      />

      <section className={b('content')}>
        {step === StartStep.APP && <AddAppPage />}
        {step === StartStep.GROUP && appsState.current && (
          <AddGroupPage currentAppId={appsState.current.id} onGroupCreated={handleGroupCreated} />
        )}
        {step === StartStep.STORIES && currentGroup && (
          <ChooseTemplatePage
            currentGroup={currentGroup}
            templateId={currentTemplateId}
            onSelectTemplate={handleSelectTemplate}
          />
        )}
      </section>
    </div>
  );
};
