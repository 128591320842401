import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useHistory, useParams } from 'react-router-dom';
import { RootState, useAppDispatch } from '@store';
import { useSelector } from 'react-redux';
import {
  storiesAnalyticsGroupSlice,
  fetchAnalyticsStoriesGroup,
  fetchAnalyticsStory,
  StoryPreview,
  StoryAnalyticsModal
} from '@features';
import { ErrorBoundary } from 'react-error-boundary';
import { StoryRenderingFallback } from '@features/stories/components/StoryRenderingFallback/StoryRenderingFallback';
import Skeleton from 'react-loading-skeleton';
import { DateTime } from 'luxon';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconChartFresh } from '@components';
import './AnalyticsInteractionsTab.scss';

const b = block('AnalyticsInteractionsTab');
const d = block('DashboardStories');

export const AnalyticsInteractionsTab = () => {
  const dispatch = useAppDispatch();

  const { appId, groupId } = useParams<{ appId: string; groupId?: string }>();
  const history = useHistory();

  const groupData = useSelector((store: RootState) => store.storiesAnalyticsGroup.data);
  const loadStatus = useSelector((store: RootState) => store.storiesAnalyticsGroup.status);
  const appsState = useSelector((store: RootState) => store.appManager);
  const appLoadStatus = appsState.status;
  const groups = useSelector((store: RootState) => store.groupsAnalytic.data);
  const groupStatus = useSelector((store: RootState) => store.groups.status);
  const range = useSelector((store: RootState) => store.storiesAnalyticsGroup.range);
  const appManager = useSelector((store: RootState) => store.appManager);

  const [currentGroupId, setCurrentGroupId] = useState<string>('');

  useEffect(() => {
    setCurrentGroupId(groupId || (groups.length ? groups[0].id : ''));
  }, [groupId, groups]);

  const { t } = useTranslation();

  const [isMoreOpen, setMoreOpen] = useState<boolean>(false);

  const handleMoreOpen = (storyId: string) => {
    setMoreOpen(true);

    if (groupData && groupData.stories.length) {
      if (appId && currentGroupId && currentGroupId !== 'all') {
        dispatch(fetchAnalyticsStory({ appId, groupId: currentGroupId, storyId, range }));
      }
    }
  };

  const handleMoreClose = () => {
    setMoreOpen(false);
    dispatch(storiesAnalyticsGroupSlice.actions.setCurrentStory(null));
  };

  useEffect(() => {
    if (
      appId &&
      currentGroupId &&
      currentGroupId !== 'all' &&
      range.from &&
      range.to &&
      appsState.status === 'loaded'
    ) {
      dispatch(fetchAnalyticsStoriesGroup({ appId, groupId: currentGroupId, range }));
    }
  }, [appId, dispatch, currentGroupId, range, appsState, appManager.current]);

  return (
    <div className={b()}>
      {appLoadStatus === 'pending' || groupStatus === 'pending' ? (
        <div className={b('controls')}>
          {Array.from(Array(4)).map(() => (
            <Skeleton height={48} style={{ borderRadius: 12, marginRight: 12 }} width={133} />
          ))}
        </div>
      ) : (
        <>
          {groupData ? (
            <div className={b('controls')}>
              {groups.map((group) => (
                <button
                  className={b('controlItem', {
                    background: group.id === currentGroupId ? 'pink' : undefined
                  })}
                  key={group.id}
                  onClick={() => {
                    history.push(`/dashboard/${appId}/analytics/${group.id}/stories`);
                  }}
                >
                  <img className={b('groupImg')} src={group.image} />
                  <p className={b('groupTitle')}>{group.title}</p>
                </button>
              ))}
            </div>
          ) : null}
        </>
      )}

      {loadStatus === 'pending' ? (
        <div className={b('stories')}>
          {Array.from(Array(4)).map(() => (
            <div className={cn(d('storyItem').toString(), b('storyItem').toString())}>
              <Skeleton height={416} style={{ borderRadius: 12 }} width={234} />
            </div>
          ))}
        </div>
      ) : (
        <>
          {groupData && groupData.stories.length ? (
            <>
              <div className={b('stories')}>
                {[...groupData.stories]
                  .sort((storyA, storyB) => (storyA.position < storyB.position ? -1 : 1))
                  .map((story) => (
                    <div
                      className={cn(d('storyItem').toString(), b('storyItem').toString())}
                      key={`story-analytic-card-${story.id}`}
                    >
                      <div className={d('story')}>
                        <ErrorBoundary FallbackComponent={StoryRenderingFallback}>
                          <StoryPreview dashboardVariant story={story} />
                        </ErrorBoundary>

                        <div className={b('storyStat')}>
                          <div className={b('storyStatGeneral')}>
                            <div className={b('storyStatGeneralItem')}>
                              <p className={b('storyStatGeneralItemTitle')}>
                                {t('dashboard.analytics.interactions')}
                              </p>
                              <p className={b('storyStatGeneralItemNumber')}>
                                {story.statistic?.actions || 0}
                              </p>
                              <p className={b('storyStatGeneralItemDesc')}>
                                {t('dashboard.analytics.actions')}
                              </p>
                            </div>

                            <div className={b('storyStatGeneralItem')}>
                              <p className={b('storyStatGeneralItemTitle')}>
                                {t('dashboard.analytics.statistics')}
                              </p>
                              <p className={b('storyStatGeneralItemNumber')}>
                                {story.statistic?.views || 0}
                              </p>
                              <p className={b('storyStatGeneralItemDesc')}>
                                {t('dashboard.analytics.views')}
                              </p>
                            </div>
                          </div>

                          <div className={b('storyStatButtonContainer')}>
                            <button
                              className={b('storyStatButton')}
                              onClick={() => handleMoreOpen(story.id)}
                            >
                              <IconChartFresh className={b('storyStatButtonIcon')} />
                              <span className={b('storyStatButtonText')}>
                                {t('dashboard.analytics.more')}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={d('storyParams')}>
                        <div className={d('storyParamsItem')}>
                          {DateTime.fromISO(story.createdAt)
                            .toLocaleString({
                              day: 'numeric',
                              month: 'numeric',
                              year: '2-digit'
                            })
                            .replaceAll('/', '.')}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <StoryAnalyticsModal isOpen={isMoreOpen} onClose={handleMoreClose} />
            </>
          ) : (
            <div className={b('stories')}>
              <div className={cn(d('storyItem').toString(), b('storyItem').toString())}>
                <div className={b('emptyStory')}>
                  <p className={b('emptyStoryTitle')}>{t('dashboard.analytics.groups.noData')}</p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
