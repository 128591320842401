import React, { useEffect } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { fetchInitSubscriptions } from '@features';
import Skeleton from 'react-loading-skeleton';
import { IconCheckArrow } from '@components';
import { formatNumber } from '@utils';
import { PriceMauCard, SubscriptionCard } from './components';
import './OrderSummaryTab.scss';

const b = block('OrderSummaryTab');

interface OrderSummaryTabProps {
  appId: string;
}

export const OrderSummaryTab: React.FC<OrderSummaryTabProps> = () => {
  const { t } = useTranslation();

  const user = useSelector((state: RootState) => state.user.user);
  const products = useSelector((store: RootState) => store.products.products);
  const productsStatus = useSelector((store: RootState) => store.products.status);
  const app = useSelector((store: RootState) => store.appManager);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchInitSubscriptions());
  }, []);

  return (
    <div className={b()}>
      <div className={b('col')}>
        <div className={b('header')}>
          <p className={b('title')}>{t('dashboard.billing.billing')}</p>
          <p className={b('desc')}>{t('dashboard.billing.billingSubscription')}</p>
        </div>
        <div className={b('cards')}>
          {products.length > 0 &&
            productsStatus === 'loaded' &&
            (app.current || (app.status === 'loaded' && !app.appsList.length)) ? (
            <>
              <PriceMauCard
                currentPlan={{
                  mauLimit: user.mauLimit,
                  currency: 'usd',
                  subscriptionId: user.subscriptionId
                }}
                priceId={products[0].defaultPrice?.id ?? ''}
                tiers={
                  products[0].tiers?.map((tier) => ({
                    price: tier.flatAmount,
                    upFrom: tier.upFrom,
                    upTo: tier.upTo
                  })) ?? []
                }
              />
              <SubscriptionCard />
            </>
          ) : (
            <>
              <Skeleton
                height={218}
                style={{
                  borderRadius: 12
                }}
                width="100%"
              />

              <Skeleton
                height={218}
                style={{
                  borderRadius: 12
                }}
                width="100%"
              />
            </>
          )}
        </div>
      </div>{' '}
      {products.length > 0 && productsStatus === 'loaded' && (
        <div className={b('productFeatures')}>
          <p className={b('productFeaturesTitle')}>{t('plans.keyFeatures')}</p>
          <div className={b('featureList')}>
            <div className={b('feature')}>
              <IconCheckArrow className={b('featureIcon').toString()} />
              <p className={b('featureTitle', { bold: true })}>
                {!user.mauLimit ? '∞' : formatNumber(user.mauLimit)}{' '}
                {t('dashboard.billing.activeUsers')}
              </p>
            </div>
            {products[0].features?.map((feature, index) => (
              <div className={b('feature')} key={`feature-${index}`}>
                <IconCheckArrow className={b('featureIcon').toString()} />
                <p className={b('featureTitle', { bold: index < 3 })}>{feature.name}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
