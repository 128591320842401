import React, { useState } from 'react';
import Pie from '@visx/shape/lib/shapes/Pie';
import { Group } from '@visx/group';
import { scaleOrdinal } from '@visx/scale';
import { block } from 'bem-cn';
import Skeleton from 'react-loading-skeleton';
import './PieChart.scss';

export type PieChartProps = {
  width: number;
  height: number;
  isLoading?: boolean;
  data: { label: string; value: number; id: string }[];
  margin?: { top: number; right: number; bottom: number; left: number };
};

const defaultMargin = { top: 5, right: 5, bottom: 5, left: 5 };

const COLORS = [
  '#2BB42B',
  '#FFA100',
  '#0090FF',
  '#FF5733',
  '#C70039',
  '#900C3F',
  '#581845',
  '#DAF7A6',
  '#FFC300',
  '#FF5733',
  '#C70039',
  '#900C3F',
  '#581845'
];

const b = block('PieChart');

export const PieChart: React.FC<PieChartProps> = ({
  width,
  height,
  data,
  margin = defaultMargin,
  isLoading
}) => {
  const [hoveredValue, setHoveredValue] = useState<string | null>(null);

  if (width < 10) return null;

  const totalValue = data?.reduce((acc, item) => acc + item.value, 0);

  const getItemColor = scaleOrdinal({
    domain: data?.map((d) => d.id),
    range: COLORS
  });

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;

  return (
    <div className={b()}>
      <div className={b('legend')}>
        {isLoading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <div className={b('legendItem')} key={index}>
              <div className={b('legendItemContainer')}>
                <Skeleton height={19} width={18} />
                <Skeleton height={14} width={100} />
              </div>

              <Skeleton height={14} width={20} />
            </div>
          ))
        ) : (
          <>
            {data
              ?.sort((a, b) => b.value - a.value)
              .filter((item) => item.value > 0)
              .map((item) => (
                <div
                  className={b('legendItem', { hovered: hoveredValue === item.id })}
                  key={item.label}
                >
                  <div className={b('legendItemContainer')}>
                    <div
                      className={b('legendColor')}
                      style={{ backgroundColor: getItemColor(item.label) }}
                    />
                    <span className={b('legendLabel')}>{item.label || 'Unknown'}</span>
                  </div>
                  <span className={b('legendValue')}>
                    {' '}
                    {totalValue === 0
                      ? '0%'
                      : `${(item.value / totalValue) * 100 > 1
                        ? ((item.value / totalValue) * 100).toFixed(0)
                        : '<1'
                      }%`}
                  </span>
                </div>
              ))}
          </>
        )}
      </div>

      {isLoading ? (
        <Skeleton
          height={height}
          style={{
            borderRadius: '50%'
          }}
          width={width}
        />
      ) : (
        <div className={b('chart')}>
          <svg height={height} width={width}>
            <Group left={centerX + margin.left} top={centerY + margin.top}>
              <Pie data={data} innerRadius={0} outerRadius={radius} pieValue={(d) => d.value}>
                {(pie) =>
                  pie.arcs.map((arc, i) => {
                    const path = pie.path(arc);
                    return (
                      <g
                        key={`arc-${i}`}
                        // transform={
                        //   hoveredValue === arc.data.id
                        //     ? `scale(1.1) translate(${centerX * -0.05}, ${centerY * -0.05})`
                        //     : ''
                        // }
                        onMouseEnter={() => setHoveredValue(arc.data.id)}
                        onMouseLeave={() => setHoveredValue(null)}
                      >
                        <path d={path ?? ''} fill={getItemColor(arc.data.label)} />
                      </g>
                    );
                  })
                }
              </Pie>
            </Group>
          </svg>
        </div>
      )}
    </div>
  );
};
