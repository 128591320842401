import React from 'react';
import PropTypes from 'prop-types';

const IconStatTablet = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 18 24"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1882 21.1698H1.3135C0.971297 21.1698 0.695312 20.9907 0.695312 20.7686V3.22345C0.695312 3.00137 0.971297 2.82227 1.3135 2.82227H17.1771C17.5193 2.82227 17.7953 3.00137 17.7953 3.22345V20.7686C17.7953 20.9907 17.5193 21.1698 17.1882 21.1698Z"
      fill="#05051D"
      opacity="0.2"
    />
    <path
      clipRule="evenodd"
      d="M1.31727 24H16.6827C17.9522 24 18 23.2836 18 22.3953V1.59757C18 0.716421 17.9522 0 16.6827 0H1.31727C0.0478408 0 0 0.716421 0 1.59757V22.4024C0 23.2836 0.0478408 24 1.31727 24ZM1.30011 21.1698H16.7106C17.032 21.1698 17.3 20.9907 17.3 20.7686V3.22345C17.3 3.00137 17.0321 2.82227 16.6999 2.82227H1.30011C0.967915 2.82227 0.7 3.00137 0.7 3.22345V20.7686C0.7 20.9907 0.967915 21.1698 1.30011 21.1698ZM9 23.3567C8.46141 23.3567 8.025 22.9203 8.025 22.3817C8.025 21.8431 8.46141 21.4067 9 21.4067C9.53859 21.4067 9.975 21.8431 9.975 22.3817C9.975 22.9203 9.53859 23.3567 9 23.3567ZM8.5 1.36C8.5 1.6362 8.7238 1.86 9 1.86C9.2762 1.86 9.5 1.6362 9.5 1.36C9.5 1.0838 9.2762 0.86 9 0.86C8.7238 0.86 8.5 1.0838 8.5 1.36Z"
      fill="#05051D"
      fillRule="evenodd"
    />
  </svg>
);

IconStatTablet.propTypes = {
  className: PropTypes.string
};

IconStatTablet.defaultProps = {
  className: ''
};

export default IconStatTablet;
