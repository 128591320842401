import React, { useEffect } from 'react';
import block from 'bem-cn';
import {
  AnalyticsInteractionsTab,
  AnalyticsLogicsTab,
  AnalyticsStoryGroupsTab
} from '@modules/DashboardModule/components';
import { useRedirectIfNotConfirmed } from '@features';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { Tabs, TabType } from '@components';
import { usePrevious } from '@hooks';
import './AppAnalyticsRoute.scss';

const b = block('AppAnalyticsRoute');

export const AppAnalyticsRoute: React.FC = () => {
  useRedirectIfNotConfirmed();

  const { appId, groupId, tab } = useParams<{
    appId: string;
    groupId?: string;
    tab: 'stories' | 'logics';
  }>();

  const prevAppId = usePrevious(appId);

  const { t } = useTranslation();
  const history = useHistory();
  const groups = useSelector((store: RootState) => store.groupsAnalytic.data);

  const firstGroupId = groups.length ? groups[0].id : '';

  useEffect(() => {
    if (appId && prevAppId && appId !== prevAppId) {
      history.push(`/dashboard/${appId}/analytics`);
    }
  }, [appId]);

  const tabsItems: TabType[] = [
    {
      id: 'AnalyticsStoryGroupsTab',
      title: t('dashboard.analytics.storyGroups'),
      component: AnalyticsStoryGroupsTab,
      onClick: () => {
        history.push(`/dashboard/${appId}/analytics`);
      }
    },
    {
      id: 'Interactions',
      title: t('dashboard.analytics.interactions'),
      component: tab === 'logics' ? AnalyticsLogicsTab : AnalyticsInteractionsTab,
      onClick: () => {
        history.push(
          `/dashboard/${appId}/analytics/${groupId ?? firstGroupId}/${tab ?? 'stories'}`
        );
      }
    }
  ];

  const tabsCurrent = tabsItems.filter((tabItem) => {
    if (!firstGroupId) {
      return tabItem.id === 'AnalyticsStoryGroupsTab';
    }
    return true;
  });

  return (
    <div className={b()}>
      <div className={b('tabs')}>
        <Tabs
          initialTabId={groupId ? 'Interactions' : 'AnalyticsStoryGroupsTab'}
          tabs={tabsCurrent}
        />
      </div>
    </div>
  );
};
