import React, { useEffect, useState } from 'react';
import { API } from '@services';
import { useTranslation } from 'react-i18next';
import { IconStatAndroid, IconStatIos, IconStatWeb } from '@components';
import { AnalyticsPercentageTable } from '../AnalyticsPercentageTable';
import './AnalyticsOsStat.scss';

interface AnalyticsOsStat {
  appId: string;
  dateFrom: string;
  dateTo: string;
}

enum OsType {
  IOS = 'ios',
  WEB = 'web',
  ANDROID = 'android'
}

interface OsStat {
  icon: JSX.Element;
  title: string;
  value: number;
}

export const AnalyticsOsStat: React.FC<AnalyticsOsStat> = ({ appId, dateFrom, dateTo }) => {
  const [osStats, setOstats] = useState<OsStat[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const osTranslation = {
    ios: t('dashboard.analytics.ios'),
    web: t('dashboard.analytics.web'),
    android: t('dashboard.analytics.android')
  };

  const getOsType = (os: string) => {
    if (os.includes('ios')) {
      return OsType.IOS;
    }

    if (os.includes('android')) {
      return OsType.ANDROID;
    }

    return OsType.WEB;
  };

  const getOsIcon = (os: OsType) => {
    switch (os) {
      case OsType.IOS:
        return <IconStatIos />;
      case OsType.ANDROID:
        return <IconStatAndroid />;
      case OsType.WEB:
        return <IconStatWeb />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setOstats([]);

      const { data } = await API.analytics.getReport({
        appId,
        unit: 'os',
        dateFrom,
        dateTo
      });

      if (data.data && !data.error) {
        const groupedData = data.data?.reduce((acc: any, item: any) => {
          const osType = getOsType(item.value);
          if (!acc[osType]) {
            acc[osType] = { total: 0, type: osType };
          }
          acc[osType].total += item.total;
          return acc;
        }, {});

        setOstats(
          Object.values(groupedData).map((item: any) => ({
            icon: getOsIcon(item.type) as React.ReactElement,
            title: osTranslation[item.type as OsType],
            value: item.total
          })) ?? []
        );
      }

      setIsLoading(false);
    };

    if (dateFrom && dateTo) {
      fetchData();
    }
  }, [dateFrom, dateTo, appId]);

  useEffect(() => {
    if (!osStats?.length && !isLoading) {
      setOstats([
        {
          icon: <IconStatIos />,
          title: osTranslation[OsType.IOS],
          value: 0
        },
        {
          icon: <IconStatAndroid />,
          title: osTranslation[OsType.ANDROID],
          value: 0
        },
        {
          icon: <IconStatWeb />,
          title: osTranslation[OsType.WEB],
          value: 0
        }
      ]);
    }
  }, [osStats, isLoading]);

  return (
    <AnalyticsPercentageTable
      isLoading={isLoading}
      items={osStats}
      title={t('dashboard.analytics.os')}
    />
  );
};
