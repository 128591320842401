import React, { useEffect, useState } from 'react';
import { API } from '@services';
import iso3311a2 from 'iso-3166-1-alpha-2';
import { PieChart } from '@components';
import { useIsMobile } from '@hooks';
import './AnalyticsCountriesStat.scss';

interface AnalyticsCountriesStatProps {
  appId: string;
  dateFrom: string;
  dateTo: string;
}

export const AnalyticsCountriesStat: React.FC<AnalyticsCountriesStatProps> = ({
  appId,
  dateFrom,
  dateTo
}) => {
  const [countryStats, setCountryStats] = useState<{ label: string; value: number; id: string }[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setCountryStats([]);

      const { data } = await API.analytics.getReport({
        appId,
        unit: 'country',
        dateFrom,
        dateTo
      });

      if (data.data && !data.error) {
        setCountryStats(
          data.data
            ?.map((item: any, index: number) => ({
              label: item.value.length === 2 ? iso3311a2.getCountry(item.value) : item.value,
              value: item.total,
              id: `${item.value}-${index}`
            }))
            .reduce((acc: any, item: any) => {
              const existing = acc.find((i: any) => i.label === item.label);
              if (existing) {
                existing.value += item.value;
              } else {
                acc.push(item);
              }
              return acc;
            }, []) ?? []
        );
      }

      setIsLoading(false);
    };

    if (dateFrom && dateTo) {
      fetchData();
    }
  }, [dateFrom, dateTo, appId]);

  useEffect(() => {
    if (!countryStats?.length && !isLoading) {
      setCountryStats([
        {
          label: 'Nowhere',
          value: 1,
          id: 'nowhere'
        }
      ]);
    }
  }, [countryStats, isLoading]);

  return (
    <PieChart
      data={countryStats}
      height={isMobile ? 100 : 200}
      isLoading={isLoading}
      width={isMobile ? 100 : 200}
    />
  );
};
