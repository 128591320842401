import React, { useMemo } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { FreshButton } from '@components/_fresh';
import { Icon } from '@components';
import { shortNumber } from '@utils';
import './MauCounter.scss';

const b = block('MauCounter');

interface MauCounterProps {
  mau: number;
  mauLimit: number;
  month: number;
  onUpgradeClick?: () => void;
}

export const MauCounter: React.FC<MauCounterProps> = ({ mau, mauLimit, month, onUpgradeClick }) => {
  const { t } = useTranslation();

  const progressPercent = useMemo(() => {
    const value = (mauLimit ? mau / mauLimit : 0) * 100;

    if (value > 100) {
      return 100;
    }

    return value;
  }, [mau, mauLimit]);

  return (
    <div className={b()}>
      <div className={b('container')}>
        <p className={b('mau')}>
          {shortNumber(mau)}/{mauLimit ? shortNumber(mauLimit) : '∞'} {t('dashboard.usage.mau')}
        </p>
        {mauLimit > 0 && (
          <>
            <div className={b('progress')}>
              <div
                className={b('progressBar')}
                style={{
                  width: `${progressPercent}%`
                }}
              />
            </div>
            <p className={b('month')}>
              {t(`monthes.${month}`)} {`${Math.round((mauLimit ? mau / mauLimit : 0) * 100)}%`}
            </p>
          </>
        )}
      </div>
      {onUpgradeClick && mauLimit > 0 && (
        <FreshButton
          leftIcon={<Icon name="upgrade-arrow" variant="icons" />}
          size="sm"
          text={t('plans.upgrade')}
          onClick={onUpgradeClick}
        />
      )}
    </div>
  );
};
