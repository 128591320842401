import React from 'react';
import { RootState } from '@store';
import { getScalableValue, createObjectWithPosition } from '@features';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import {
  ChooseAnswerWidget,
  EmojiReactionWidget,
  QuestionWidget,
  QuizMultipleAnswerWidget,
  QuizMultipleAnswerWithImageWidget,
  QuizOneAnswerWidget,
  QuizOpenAnswerWidget,
  QuizRateWidget,
  SliderWidget,
  TalkAboutWidget,
  WidgetsTypes
} from '@storysdk/react';
import { useStoryEditorDispatch } from '@modules';
import { useGetCurrentApp } from '@features/stories/hooks';
import { WIDGET_PARAMS } from './consts';
import { WidgetItem, WidgetList } from './WidgetList/WidgetList';
import { NoWidgetsAccessMessage } from './NoWidgetsAccessMessage';

export const EditorWidgetsTab: React.FC = () => {
  const { t } = useTranslation();

  const user = useSelector((store: RootState) => store.user.user);
  const recentFont = useSelector((store: RootState) => store.fonts.recentFont);
  const storyEditorDispatch = useStoryEditorDispatch();

  const currentApp = useGetCurrentApp();

  const isWidgetsAvailable = true;

  const isAppOwner = currentApp && currentApp.ownerId === user.id;

  return (
    <WidgetList>
      {!isWidgetsAvailable && <NoWidgetsAccessMessage isAppOwner={isAppOwner ?? false} />}
      <WidgetItem
        isDisabled={!isWidgetsAvailable}
        onClick={createObjectWithPosition({
          dispatch: storyEditorDispatch,
          type: WidgetsTypes.SLIDER,
          position: {
            x: WIDGET_PARAMS[WidgetsTypes.SLIDER].x,
            y: WIDGET_PARAMS[WidgetsTypes.SLIDER].y,
            width: WIDGET_PARAMS[WidgetsTypes.SLIDER].width,
            height: WIDGET_PARAMS[WidgetsTypes.SLIDER].height,
            realWidth: WIDGET_PARAMS[WidgetsTypes.SLIDER].width,
            realHeight: WIDGET_PARAMS[WidgetsTypes.SLIDER].height,
            isHeightLocked: true
          },
          originPosition: {
            width: WIDGET_PARAMS[WidgetsTypes.SLIDER].width,
            height: WIDGET_PARAMS[WidgetsTypes.SLIDER].height
          },
          recentFont
        })}
      >
        <SliderWidget
          isReadOnly
          params={{
            color: 'white',
            emoji: { name: 'smile', unicode: '1f604' },
            value: 50,
            text: t('editor.widgets.howAreYou'),
            fontFamily: 'Roboto',
            fontParams: { style: 'normal', weight: 400 },
            fontColor: { type: 'color', value: '#000000' }
          }}
          onChangeParams={() => undefined}
        />
      </WidgetItem>
      <WidgetItem
        isDisabled={!isWidgetsAvailable}
        onClick={createObjectWithPosition({
          dispatch: storyEditorDispatch,
          type: WidgetsTypes.QUESTION,
          position: {
            x: WIDGET_PARAMS[WidgetsTypes.QUESTION].x,
            y: WIDGET_PARAMS[WidgetsTypes.QUESTION].y,
            width: WIDGET_PARAMS[WidgetsTypes.QUESTION].width,
            height: WIDGET_PARAMS[WidgetsTypes.QUESTION].height,
            realWidth: WIDGET_PARAMS[WidgetsTypes.QUESTION].width,
            realHeight: WIDGET_PARAMS[WidgetsTypes.QUESTION].height,
            isHeightLocked: true
          },
          originPosition: {
            width: WIDGET_PARAMS[WidgetsTypes.QUESTION].width,
            height: WIDGET_PARAMS[WidgetsTypes.QUESTION].height
          },
          recentFont
        })}
      >
        <QuestionWidget
          isReadOnly
          params={{
            question: t('editor.widgets.howAreYou'),
            confirm: t('form.yes'),
            decline: t('form.no'),
            color: '#ffffff',
            isTitleHidden: false,
            fontFamily: 'Roboto',
            fontParams: { style: 'normal', weight: 500 },
            fontColor: { type: 'color', value: '#FFFFFF' }
          }}
          onChange={() => undefined}
          onChangeParams={() => undefined}
        />
      </WidgetItem>
      <WidgetItem
        isDisabled={!isWidgetsAvailable}
        onClick={createObjectWithPosition({
          dispatch: storyEditorDispatch,
          type: WidgetsTypes.TALK_ABOUT,
          position: {
            x: WIDGET_PARAMS[WidgetsTypes.TALK_ABOUT].x,
            y: WIDGET_PARAMS[WidgetsTypes.TALK_ABOUT].y,
            width: WIDGET_PARAMS[WidgetsTypes.TALK_ABOUT].width,
            height: WIDGET_PARAMS[WidgetsTypes.TALK_ABOUT].height,
            realWidth: WIDGET_PARAMS[WidgetsTypes.TALK_ABOUT].width,
            realHeight: WIDGET_PARAMS[WidgetsTypes.TALK_ABOUT].height,
            isHeightLocked: true
          },
          originPosition: {
            width: WIDGET_PARAMS[WidgetsTypes.TALK_ABOUT].width,
            height: WIDGET_PARAMS[WidgetsTypes.TALK_ABOUT].height
          },
          recentFont
        })}
      >
        <TalkAboutWidget
          isReadOnly
          params={{
            text: t('editor.widgets.talkAbout'),
            image: null,
            color: 'white',
            isTitleHidden: false,
            fontFamily: 'Roboto',
            fontParams: { style: 'normal', weight: 500 },
            fontColor: { type: 'color', value: '#000000' }
          }}
        />
      </WidgetItem>
      <WidgetItem
        isDisabled={!isWidgetsAvailable}
        onClick={createObjectWithPosition({
          dispatch: storyEditorDispatch,
          type: WidgetsTypes.EMOJI_REACTION,
          position: {
            width: 'auto',
            height: getScalableValue(WIDGET_PARAMS[WidgetsTypes.EMOJI_REACTION].height),
            realWidth: getScalableValue(WIDGET_PARAMS[WidgetsTypes.EMOJI_REACTION].width),
            realHeight: getScalableValue(WIDGET_PARAMS[WidgetsTypes.EMOJI_REACTION].height)
          },
          originPosition: {
            width: getScalableValue(WIDGET_PARAMS[WidgetsTypes.EMOJI_REACTION].width),
            height: getScalableValue(WIDGET_PARAMS[WidgetsTypes.EMOJI_REACTION].height)
          },
          recentFont
        })}
      >
        <EmojiReactionWidget
          isReadOnly
          params={{
            color: 'white',
            emoji: [
              { name: 'smile', unicode: '1f604' },
              { name: 'thumbsup', unicode: '1f44d' },
              { name: 'fire', unicode: '1f525' }
            ]
          }}
        />
      </WidgetItem>
      {/* <WidgetItem
        onClick={createObject(WidgetsTypes.TIMER, {
          width: getScalableValue(196),
          height: 119,
          realWidth: getScalableValue(196),
          realHeight: 119
        })}
      >
        <TimerWidgetControl
          params={{
            time: DateTime.now().toUTC().toMillis(),
            text: 'Timer',
            color: 'white'
          }}
        />
      </WidgetItem> */}
      <WidgetItem
        isDisabled={!isWidgetsAvailable}
        onClick={createObjectWithPosition({
          dispatch: storyEditorDispatch,
          type: WidgetsTypes.CHOOSE_ANSWER,
          position: {
            x: WIDGET_PARAMS[WidgetsTypes.CHOOSE_ANSWER].x,
            y: WIDGET_PARAMS[WidgetsTypes.CHOOSE_ANSWER].y,
            width: WIDGET_PARAMS[WidgetsTypes.CHOOSE_ANSWER].width,
            height: WIDGET_PARAMS[WidgetsTypes.CHOOSE_ANSWER].height,
            realWidth: WIDGET_PARAMS[WidgetsTypes.CHOOSE_ANSWER].width,
            realHeight: WIDGET_PARAMS[WidgetsTypes.CHOOSE_ANSWER].height,
            isHeightLocked: true
          },
          originPosition: {
            width: WIDGET_PARAMS[WidgetsTypes.CHOOSE_ANSWER].width,
            height: WIDGET_PARAMS[WidgetsTypes.CHOOSE_ANSWER].height
          },
          recentFont
        })}
      >
        <ChooseAnswerWidget
          isReadOnly
          params={{
            text: t('editor.widgets.chooseAnswer'),
            color: 'black',
            markCorrectAnswer: false,
            answers: [
              {
                id: 'A',
                title: t('editor.widgets.answers.answer1'),
                score: {
                  letter: 'A',
                  points: 0
                }
              },
              {
                id: 'B',
                title: t('editor.widgets.answers.answer2'),
                score: {
                  letter: 'A',
                  points: 0
                }
              }
            ],
            correct: 'A',
            isTitleHidden: false,
            fontFamily: 'Roboto',
            fontParams: { style: 'normal', weight: 400 },
            fontColor: { type: 'color', value: '#000000' }
          }}
        />
      </WidgetItem>
      <WidgetItem
        isDisabled={!isWidgetsAvailable}
        isFilled
        onClick={createObjectWithPosition({
          dispatch: storyEditorDispatch,
          type: WidgetsTypes.QUIZ_ONE_ANSWER,
          position: {
            x: WIDGET_PARAMS[WidgetsTypes.QUIZ_ONE_ANSWER].x,
            y: WIDGET_PARAMS[WidgetsTypes.QUIZ_ONE_ANSWER].y,
            width: WIDGET_PARAMS[WidgetsTypes.QUIZ_ONE_ANSWER].width,
            height: WIDGET_PARAMS[WidgetsTypes.QUIZ_ONE_ANSWER].height,
            realWidth: WIDGET_PARAMS[WidgetsTypes.QUIZ_ONE_ANSWER].width,
            realHeight: WIDGET_PARAMS[WidgetsTypes.QUIZ_ONE_ANSWER].height,
            isHeightLocked: true
          },
          originPosition: {
            width: WIDGET_PARAMS[WidgetsTypes.QUIZ_ONE_ANSWER].width,
            height: WIDGET_PARAMS[WidgetsTypes.QUIZ_ONE_ANSWER].height
          },
          recentFont
        })}
      >
        <QuizOneAnswerWidget
          isReadOnly
          params={{
            title: t('editor.widgets.quizOneAnswer'),
            answers: [
              {
                id: 'A',
                emoji: { name: 'smile', unicode: '1f604' },
                title: t('editor.widgets.answers.answer1'),
                score: {
                  letter: 'A',
                  points: 0
                }
              },
              {
                id: 'B',
                emoji: { name: 'smile', unicode: '1f604' },
                title: t('editor.widgets.answers.answer2'),
                score: {
                  letter: 'A',
                  points: 0
                }
              }
            ],
            isTitleHidden: false,
            titleFont: {
              fontFamily: 'Roboto',
              fontParams: { style: 'normal', weight: 500 },
              fontColor: { type: 'color', value: '#FFFFFF' }
            },
            answersFont: {
              fontFamily: 'Roboto',
              fontParams: { style: 'normal', weight: 500 },
              fontColor: { type: 'color', value: '#000000' }
            }
          }}
        />
      </WidgetItem>
      <WidgetItem
        isDisabled={!isWidgetsAvailable}
        isFilled
        onClick={createObjectWithPosition({
          dispatch: storyEditorDispatch,
          type: WidgetsTypes.QUIZ_MULTIPLE_ANSWERS,
          position: {
            x: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWERS].x,
            y: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWERS].y,
            width: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWERS].width,
            height: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWERS].height,
            realWidth: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWERS].width,
            realHeight: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWERS].height,
            isHeightLocked: true
          },
          originPosition: {
            width: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWERS].width,
            height: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWERS].height
          },
          recentFont
        })}
      >
        <QuizMultipleAnswerWidget
          isReadOnly
          params={{
            title: t('editor.widgets.quizMultipleAnswer'),
            answers: [
              {
                id: 'A',
                emoji: { name: 'smile', unicode: '1f604' },
                title: t('editor.widgets.answers.answer1'),
                score: {
                  letter: 'A',
                  points: 0
                }
              },
              {
                id: 'B',
                emoji: { name: 'smile', unicode: '1f604' },
                title: t('editor.widgets.answers.answer2'),
                score: {
                  letter: 'A',
                  points: 0
                }
              }
            ],
            isTitleHidden: false,
            titleFont: {
              fontFamily: 'Roboto',
              fontParams: { style: 'normal', weight: 500 },
              fontColor: { type: 'color', value: '#FFFFFF' }
            },
            answersFont: {
              fontFamily: 'Roboto',
              fontParams: { style: 'normal', weight: 500 },
              fontColor: { type: 'color', value: '#000000' }
            }
          }}
        />
      </WidgetItem>
      <WidgetItem
        isDisabled={!isWidgetsAvailable}
        isFilled
        onClick={createObjectWithPosition({
          dispatch: storyEditorDispatch,
          type: WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE,
          position: {
            x: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE].x,
            y: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE].y,
            width: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE].width,
            height: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE].height,
            realWidth: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE].width,
            realHeight: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE].height,
            isHeightLocked: true
          },
          originPosition: {
            width: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE].width,
            height: WIDGET_PARAMS[WidgetsTypes.QUIZ_MULTIPLE_ANSWER_WITH_IMAGE].height
          },
          recentFont
        })}
      >
        <QuizMultipleAnswerWithImageWidget
          isReadOnly
          params={{
            title: t('editor.widgets.quizMultipleAnswerWithImage'),
            answers: [
              {
                id: nanoid(),
                title: t('editor.widgets.answers.answer1'),
                score: {
                  letter: 'A',
                  points: 0
                }
              },
              {
                id: nanoid(),
                title: t('editor.widgets.answers.answer2'),
                score: {
                  letter: 'A',
                  points: 0
                }
              }
            ],
            isTitleHidden: false,
            titleFont: {
              fontFamily: 'Roboto',
              fontParams: { style: 'normal', weight: 500 },
              fontColor: { type: 'color', value: '#FFFFFF' }
            },
            answersFont: {
              fontFamily: 'Roboto',
              fontParams: { style: 'normal', weight: 500 },
              fontColor: { type: 'color', value: '#000000' }
            }
          }}
        />
      </WidgetItem>
      <WidgetItem
        isDisabled={!isWidgetsAvailable}
        isFilled
        onClick={createObjectWithPosition({
          dispatch: storyEditorDispatch,
          type: WidgetsTypes.QUIZ_OPEN_ANSWER,
          position: {
            x: WIDGET_PARAMS[WidgetsTypes.QUIZ_OPEN_ANSWER].x,
            y: WIDGET_PARAMS[WidgetsTypes.QUIZ_OPEN_ANSWER].y,
            width: WIDGET_PARAMS[WidgetsTypes.QUIZ_OPEN_ANSWER].width,
            height: WIDGET_PARAMS[WidgetsTypes.QUIZ_OPEN_ANSWER].height,
            realWidth: WIDGET_PARAMS[WidgetsTypes.QUIZ_OPEN_ANSWER].width,
            realHeight: WIDGET_PARAMS[WidgetsTypes.QUIZ_OPEN_ANSWER].height,
            isHeightLocked: true
          },
          originPosition: {
            width: WIDGET_PARAMS[WidgetsTypes.QUIZ_OPEN_ANSWER].width,
            height: WIDGET_PARAMS[WidgetsTypes.QUIZ_OPEN_ANSWER].height
          },
          recentFont
        })}
      >
        <QuizOpenAnswerWidget
          isReadOnly
          params={{
            title: t('editor.widgets.quizOpenAnswer'),
            isTitleHidden: false,
            fontFamily: 'Roboto',
            fontParams: { style: 'normal', weight: 500 },
            fontColor: { type: 'color', value: '#FFFFFF' }
          }}
        />
      </WidgetItem>
      <WidgetItem
        isDisabled={!isWidgetsAvailable}
        isFilled
        onClick={createObjectWithPosition({
          dispatch: storyEditorDispatch,
          type: WidgetsTypes.QUIZ_RATE,
          position: {
            x: WIDGET_PARAMS[WidgetsTypes.QUIZ_RATE].x,
            y: WIDGET_PARAMS[WidgetsTypes.QUIZ_RATE].y,
            width: WIDGET_PARAMS[WidgetsTypes.QUIZ_RATE].width,
            height: WIDGET_PARAMS[WidgetsTypes.QUIZ_RATE].height,
            realWidth: WIDGET_PARAMS[WidgetsTypes.QUIZ_RATE].width,
            realHeight: WIDGET_PARAMS[WidgetsTypes.QUIZ_RATE].height,
            isHeightLocked: true
          },
          originPosition: {
            width: WIDGET_PARAMS[WidgetsTypes.QUIZ_RATE].width,
            height: WIDGET_PARAMS[WidgetsTypes.QUIZ_RATE].height
          },
          recentFont
        })}
      >
        <QuizRateWidget
          isReadOnly
          params={{
            title: t('editor.widgets.quizRate'),
            isTitleHidden: false,
            storeLinks: {
              ios: '',
              android: '',
              web: '',
              reactNative: ''
            },
            fontFamily: 'Roboto',
            fontParams: { style: 'normal', weight: 500 },
            fontColor: { type: 'color', value: '#FFFFFF' }
          }}
        />
      </WidgetItem>
    </WidgetList>
  );
};
