import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppStoriesRoute } from './pages/AppStoriesRoute/AppStoriesRoute';
import { AppSettingsRoute } from './pages/AppSettingsRoute/AppSettingsRoute';
import { AppAnalyticsRoute } from './pages/AppAnalyticsRoute/AppAnalyticsRoute';
import { DashboardLayout } from './components/DashboardLayout';
import { AppOnboardingRoute } from './pages/AppOnboardingRoute';
import { AppAnalyticsStoriesRoute } from './pages/AppAnalyticsStoriesRoute';
import { StoryTemplatesRoute } from './pages/StoryTemplatesRoute';
import { StartUseLayout } from './components';
import { AppBillingRoute } from './pages/AppBillingRoute';
import { AppIntegrationsRoute } from './pages/AppIntegrationsRoute';

export const DashboardModule: React.FC = () => (
  <Switch>
    <Route exact path="/dashboard">
      <StartUseLayout />
    </Route>
    <Route exact path="/dashboard/:appId/templates/:category?">
      <DashboardLayout isNoRedirect>
        <StoryTemplatesRoute />
      </DashboardLayout>
    </Route>
    <Route exact path="/dashboard/:appId/templates/:category/:templateId">
      <DashboardLayout isNoRedirect>
        <StoryTemplatesRoute />
      </DashboardLayout>
    </Route>
    <Route exact path="/dashboard/:appId/stories">
      <DashboardLayout>
        <AppStoriesRoute />
      </DashboardLayout>
    </Route>
    <Route exact path="/dashboard/:appId/stories/:groupId">
      <DashboardLayout>
        <AppStoriesRoute />
      </DashboardLayout>
    </Route>
    <Route exact path="/dashboard/:appId/onboarding">
      <DashboardLayout>
        <AppOnboardingRoute />
      </DashboardLayout>
    </Route>
    <Route exact path="/dashboard/:appId/onboarding/:groupId">
      <DashboardLayout>
        <AppOnboardingRoute />
      </DashboardLayout>
    </Route>
    <Route exact path="/dashboard/:appId/analytics">
      <DashboardLayout>
        <AppAnalyticsRoute />
      </DashboardLayout>
    </Route>
    <Route exact path="/dashboard/:appId/integrations">
      <DashboardLayout>
        <AppIntegrationsRoute />
      </DashboardLayout>
    </Route>
    <Route exact path="/dashboard/:appId/billing">
      <DashboardLayout>
        <AppBillingRoute />
      </DashboardLayout>
    </Route>
    <Route exact path="/dashboard/:appId/analytics/:groupId/:tab">
      <DashboardLayout>
        <AppAnalyticsRoute />
      </DashboardLayout>
    </Route>
    <Route exact path="/dashboard/:appId/settings/:tab?">
      <DashboardLayout>
        <AppSettingsRoute />
      </DashboardLayout>
    </Route>
  </Switch>
);
