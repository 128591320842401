import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API } from '@services';
import { asyncForEach } from '@utils';
import { Product, ProductsState } from './types';

const initialState: ProductsState = {
  products: [],
  status: 'idle'
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    reset: () => initialState,
    init: (state, action: PayloadAction<ProductsState>) => {
      state = action.payload;
    },
    setProducts: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
    },
    setStatus: (state, action: PayloadAction<ProductsState['status']>) => {
      state.status = action.payload;
    },
    setPlansModalType: (state, action: PayloadAction<ProductsState['plansModalType']>) => {
      state.plansModalType = action.payload;
    },
    setDowngradablePlan: (state, action: PayloadAction<ProductsState['downgradablePlan']>) => {
      state.downgradablePlan = action.payload;
    }
  }
});

export const fetchInitProducts = createAsyncThunk(
  'products/fetchInitProducts',
  async (params, { dispatch }) => {
    dispatch(productsSlice.actions.setStatus('loading'));

    const { data: productsData } = await API.subscription.products();

    if (productsData.data && !productsData.error) {
      const activeProducts = productsData.data.filter((product: any) => product.active);

      await asyncForEach(activeProducts, async (product: any) => {
        const { data: prices } = await API.subscription.prices({ productId: product.id });

        if (prices.data && !prices.error) {
          product.prices = prices.data.filter(
            (price: any) => price.active && price.currency === 'usd'
          );
        } else {
          product.prices = [];
        }
      });

      const products = activeProducts.map((product: any) => ({
        id: product.id,
        name: product.name,
        description: product.description,
        prices:
          product.name === 'Free' && product.prices.length
            ? [
              {
                id: product.prices[0].id,
                unitAmount: 0,
                currency: 'usd',
                interval: 'month'
              },
              {
                id: product.prices[0].id,
                unitAmount: 0,
                currency: 'usd',
                interval: 'year'
              }
            ]
            : product.prices.map((price: any) => ({
              id: price.id,
              unitAmount: price.unit_amount,
              currency: price.currency,
              interval: price.recurring?.interval
            })),
        features: product.marketing_features
      }));

      dispatch(productsSlice.actions.setProducts(products));
      dispatch(productsSlice.actions.setStatus('loaded'));
    } else {
      dispatch(productsSlice.actions.setStatus('loaded'));
    }
  }
);

export const fetchInitProduct = createAsyncThunk(
  'products/fetchInitProduct',
  async (params, { dispatch }) => {
    dispatch(productsSlice.actions.setStatus('loading'));

    const { data: productData } = await API.subscription.product();

    if (productData.data && !productData.error) {
      if (productData.data.active) {
        const lastTireWithUpTo = productData.data.default_price.tiers
          ? [...productData.data.default_price.tiers]
            .filter((tier: any) => tier.up_to)
            .sort((a: any, b: any) => a.up_to - b.up_to)
            .pop()
          : {
            up_to: 0
          };

        const product = {
          id: productData.data.id,
          name: productData.data.name,
          description: productData.data.description,
          features: productData.data.marketing_features,
          defaultPrice: {
            id: productData.data.default_price.id,
            unitAmount: productData.data.default_price.unit_amount,
            currency: productData.data.default_price.currency,
            interval: productData.data.default_price.recurring?.interval
          },
          tiers: productData.data.default_price.tiers.map((tier: any) => ({
            flatAmount: tier.flat_amount,
            upTo: tier.up_to,
            upFrom: tier.up_to ? 0 : lastTireWithUpTo.up_to
          }))
        };

        dispatch(productsSlice.actions.setProducts([product]));
      }
      dispatch(productsSlice.actions.setStatus('loaded'));
    } else {
      dispatch(productsSlice.actions.setStatus('loaded'));
    }
  }
);
