import React, { useEffect, useState } from 'react';
import { GroupsType, storiesSlice, StoryStatus } from '@features';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { useTranslation } from 'react-i18next';
import { FilterControlGroup, IconWarning } from '@components';
import { getActiveStoriesFlattenedArr } from '@utils';

type StoriesFilterProps = {
  type: GroupsType;
  className?: string;
};

export const StoriesFilter: React.FC<StoriesFilterProps> = ({ className, type }) => {
  const dispatch = useAppDispatch();
  const stories = useSelector((state: RootState) => state.stories);
  const app = useSelector((state: RootState) => state.appManager);
  const filter = stories.filter;

  const { t } = useTranslation();

  const [counts, setCounts] = useState({
    all: 0,
    draft: 0,
    active: 0,
    archive: 0,
    unpublished: 0,
    haveUnpublishedChanges: 0
  });

  useEffect(() => {
    const currentCount = {
      all: 0,
      draft: 0,
      active: 0,
      archive: 0,
      unpublished: 0,
      haveUnpublishedChanges: 0
    };

    if (app.currentLocale && stories.status === 'loaded') {
      const storiesArray = getActiveStoriesFlattenedArr(stories.stories);

      storiesArray.forEach((story) => {
        if (story.isHasUnpublishedChanges) {
          currentCount.haveUnpublishedChanges += 1;
        }

        if (app.currentLocale && story.storyData[app.currentLocale]) {
          const currentStatus = story.storyData[app.currentLocale].status;
          // @ts-ignore
          currentCount[currentStatus] += 1;
        }
      });
      setCounts({ ...currentCount, all: storiesArray.length });
    }
  }, [app.currentLocale, stories.status, stories.stories]);

  const handleSetStatus = (status: string) => {
    dispatch(storiesSlice.actions.setFilter(status));
  };

  const tabs = [
    {
      id: 'all',
      title: t('dashboard.stories.all'),
      count: counts.all
    },
    {
      id: StoryStatus.DRAFT,
      title: t('dashboard.stories.draft'),
      count: counts.draft
    },
    {
      id: StoryStatus.ACTIVE,
      title: t('dashboard.stories.published'),
      count: counts.active
    },
    {
      id: StoryStatus.UNPUBLISHED,
      title: t('dashboard.stories.unpublished'),
      count: counts.unpublished
    },
    {
      id: 'haveUnpublishedChanges',
      icon: <IconWarning />,
      count: counts.haveUnpublishedChanges
    }
  ].filter((item) => {
    if (!counts.haveUnpublishedChanges && item.id === 'haveUnpublishedChanges') {
      return false;
    }

    return true;
  });

  return (
    <FilterControlGroup
      className={className}
      current={filter}
      items={tabs}
      onChange={handleSetStatus}
    />
  );
};
