import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import './StoryLimitedWidgets.scss';
import { useHistory } from 'react-router-dom';
import { GroupsType, Story, fetchRemoveStory, fetchUpdateStory } from '@features';
import { useAppDispatch } from '@store';
import { FreshButton } from '@components/_fresh';
import { Icon } from '@components';

const b = block('StoryLimitedWidgets');

interface StoryLimitedWidgetsProps {
  story: Story;
  interactiveWidgetIds: string[];
  appId?: string;
  groupId?: string;
  isEditorMode?: boolean;
  borderRadius?: number;
  type: GroupsType;
}

export const StoryLimitedWidgets: React.FC<StoryLimitedWidgetsProps> = ({
  story,
  type,
  interactiveWidgetIds,
  appId,
  groupId,
  isEditorMode,
  borderRadius
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleDeleteWidgets = () => {
    const newStory = {
      ...story,
      widgets: story.widgets.filter((widget) => !interactiveWidgetIds.includes(widget.id))
    };

    dispatch(
      fetchUpdateStory({
        appId,
        groupId,
        story: newStory
      })
    );
  };

  const handleDeleteStory = () => {
    if (appId && groupId) {
      dispatch(
        fetchRemoveStory({
          appId,
          groupId,
          storyId: story.id,
          layersGroupId: story.layerData.layersGroupId
        })
      );
    }
  };

  return (
    <div
      className={b({ editor: isEditorMode })}
      style={{
        borderRadius
      }}
    >
      <p className={b('text')}>
        {type === GroupsType.GROUP
          ? t('plans.widgetFromAnotherPlanText')
          : t('plans.widgetFromAnotherPlanStoryText')}
      </p>
      <FreshButton
        className={b('button')}
        leftIcon={<Icon name="upgrade-arrow" />}
        size="md"
        text={t('plans.upgrade')}
        onClick={() => {
          history.push('/account/billing');
        }}
      />

      <FreshButton
        className={b('button')}
        color="white"
        leftIcon={<Icon name="delete" />}
        size="md"
        text={t('dashboard.stories.delete')}
        onClick={type === GroupsType.GROUP ? handleDeleteWidgets : handleDeleteStory}
      />
    </div>
  );
};
