import React from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OrderSummaryTab } from '@modules/DashboardModule/components';
import { Tabs, TabType } from '@components';
import { AccountSettingsTab } from './components/AccountSettingsTab/AccountSettingsTab';
import { UsageTab } from './components/UsageTab/UsageTab';
import './AccountSettingsRoute.scss';

const b = block('AccountSettingsRoute');

export const AccountSettingsRoute: React.FC = () => {
  const { tab } = useParams<{ tab: 'settings' | 'usage' | 'billing' }>();

  const { t } = useTranslation();

  const tabsItems: TabType[] = [
    {
      id: 'settings',
      title: t('user.accountSettings'),
      component: AccountSettingsTab
    },
    {
      id: 'billing',
      title: t('user.billing'),
      component: OrderSummaryTab
    },
    {
      id: 'usage',
      title: t('user.usage'),
      component: UsageTab
    }
  ];

  return (
    <div className={b()}>
      <div className={b('tabs')}>
        <Tabs initialTabId={tab} tabs={tabsItems} />
      </div>
    </div>
  );
};
