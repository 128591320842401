import React, { useCallback } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Message, MessageTypes } from '@features/inform/types';
import { useAppDispatch } from '@store';
import { productsSlice } from '@features/products/productsSlice';
import informSlice from '@features/inform/informSlice';
import {
  IconClose,
  IconInfoInformation,
  IconInfoError,
  IconInfoSuccess,
  IconInfoWarning,
  Icon
} from '@components';
import { FreshButton, FreshLinkOutput } from '@components/_fresh';
import './InformMessage.scss';

interface Props extends Message {
  id: string;
  closeMessage: (id: string) => void;
}

const b = block('InformMessage');

export const InformMessage: React.FC<Props> = ({
  id,
  type,
  text,
  progress = 0,
  action,
  shareLink,
  closeMessage
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useAppDispatch();

  const renderTitle = useCallback(() => {
    switch (type) {
      case MessageTypes.SUCCESS:
        return t('notification.types.success');
      case MessageTypes.SHARE:
        return t('notification.types.share');
      case MessageTypes.ERROR:
        return t('notification.types.error');
      case MessageTypes.INFO:
        return t('notification.types.info');
      case MessageTypes.WARN:
        return t('notification.types.warn');
      case MessageTypes.PROGRESS:
        return t('notification.types.progressUploading');
      case MessageTypes.NEED_ACTION:
        return t('notification.types.needAction');
      case MessageTypes.DONT_HAVE_ACCESS:
        return t('notification.types.dontHaveAccess');
      case MessageTypes.CONFIRM_EMAIL:
        return t('notification.types.confirmEmail');
      default:
        return type;
    }
  }, [t, type]);

  const renderIcon = useCallback(() => {
    switch (type) {
      case MessageTypes.SUCCESS:
        return <IconInfoSuccess className={b('icon').toString()} />;
      case MessageTypes.SHARE:
        return <IconInfoSuccess className={b('icon').toString()} />;
      case MessageTypes.ERROR:
        return <IconInfoError className={b('icon').toString()} />;
      case MessageTypes.INFO:
        return <IconInfoInformation className={b('icon').toString()} />;
      case MessageTypes.PROGRESS:
        return <IconInfoInformation className={b('icon').toString()} />;
      case MessageTypes.WARN:
        return <IconInfoWarning className={b('icon').toString()} />;
      case MessageTypes.NEED_ACTION:
        return <IconInfoWarning className={b('icon').toString()} />;
      case MessageTypes.DONT_HAVE_ACCESS:
        return <IconInfoWarning className={b('icon').toString()} />;
      case MessageTypes.CONFIRM_EMAIL:
        return <IconInfoWarning className={b('icon').toString()} />;
      default:
        return type;
    }
  }, [type]);
  return (
    <article className={b({ type })}>
      <button
        aria-label="Close"
        className={b('closeBtn')}
        tabIndex={0}
        onClick={() => closeMessage(id)}
        onKeyDown={() => closeMessage(id)}
      >
        <IconClose />
      </button>

      <section
        className={b('titleContainer', {
          marginBottom: type === MessageTypes.SHARE
        })}
      >
        {renderIcon()}
        <p className={b('title')}>{renderTitle()}</p>
      </section>

      {type === MessageTypes.SHARE && shareLink && (
        <div className={b('shareContainer')}>
          <FreshLinkOutput className={b('link').toString()} value={shareLink} />

          <FreshButton
            leftIcon={<Icon className={b('btnIcon').toString()} name="play" />}
            text={t('dashboard.modals.share.preview')}
            onClick={() => {
              window.open(shareLink, '_blank');
            }}
          />
        </div>
      )}

      {text && (
        <p className={b('text', { marginBottom: type === MessageTypes.PROGRESS || !!action })}>
          {text}
        </p>
      )}

      {action && (
        <FreshButton
          leftIcon={action.icon ? <Icon name={action.icon} /> : undefined}
          size="md"
          text={action.text}
          onClick={() => {
            if (action.link === '/account/billing') {
              history.push('/account/billing');
            } else if (action.link === '/resendConfirmation') {
              fetch(`${process.env.REACT_APP_API_URL}/auth/resend-confirm-email`, {
                method: 'post',
                body: JSON.stringify({ email: action.value })
              })
                .then((r) => r.json())
                .then((response) => {
                  if (response.error) {
                    dispatch(
                      informSlice.actions.addMessage({
                        type: MessageTypes.ERROR,
                        text: t('errors.somethingWentWrong')
                      })
                    );
                  }
                });
            } else if (action.isExternal) {
              window.open(action.link, '_blank');
            } else {
              history?.push(action.link);
            }

            closeMessage(id);
          }}
        />
      )}

      {type === MessageTypes.PROGRESS && (
        <>
          <p className={b('text', { small: true })}>{`${progress}%`}</p>
          <div className={b('progressBar')}>
            <span
              className={b('progressBarLine')}
              style={{
                width: `${progress}%`
              }}
            />
          </div>
        </>
      )}
    </article>
  );
};
