import { RootState, useAppDispatch } from '@store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchInitProduct } from '../productsSlice';

export const useFetchProduct = () => {
  const dispatch = useAppDispatch();
  const productsStatus = useSelector((store: RootState) => store.products.status);

  useEffect(() => {
    if (productsStatus === 'idle') {
      dispatch(fetchInitProduct());
    }
  }, [dispatch, productsStatus]);
};
