import React from 'react';
import PropTypes from 'prop-types';

const IconStatIos = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 22 24"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M11.8521 1.97399C12.7236 0.907495 14.2191 0.0884995 15.4986 0C15.7131 1.45799 15.1206 2.88598 14.3376 3.89548C13.5006 4.97997 12.0561 5.81847 10.6582 5.77497C10.4032 4.37847 11.0572 2.94148 11.8521 1.97399ZM15.9698 5.61707C17.6003 5.61707 19.3268 6.51107 20.5553 8.05156C16.5278 10.273 17.1803 16.06 21.2498 17.6095C20.6903 18.8575 20.4218 19.414 19.7003 20.5195C18.6938 22.0615 17.2763 23.983 15.5169 23.9965C14.7936 24.0041 14.3191 23.7852 13.8075 23.5492C13.2145 23.2756 12.5717 22.979 11.4339 22.9855C10.3024 22.9911 9.64922 23.284 9.04813 23.5535C8.52331 23.7889 8.03816 24.0065 7.3089 23.9995C5.55091 23.9845 4.20692 22.2505 3.20042 20.7085C0.38794 16.399 0.0909415 11.3395 1.82643 8.64855C3.06092 6.73756 5.00791 5.62007 6.8364 5.62007C7.81093 5.62007 8.59635 5.90168 9.34482 6.17004C10.026 6.41429 10.6766 6.64756 11.4114 6.64756C12.0912 6.64756 12.6502 6.4346 13.2599 6.20229C13.9914 5.9236 14.7959 5.61707 15.9698 5.61707Z"
      fill="#A49BA4"
      fillRule="evenodd"
    />
  </svg>
);

IconStatIos.propTypes = {
  className: PropTypes.string
};

IconStatIos.defaultProps = {
  className: ''
};

export default IconStatIos;
