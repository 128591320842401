import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ConfirmEmailModal } from '@modules/AuthModule/_components';
import { AddAppPage } from '@modules';
import {
  useFetchApps,
  useSelectApp,
  ChooseAppContainer,
  informSlice,
  MessageTypes,
  groupsSlice,
  EditGroupModal,
  GroupsType,
  Templates,
  useFetchProduct
} from '@features';
import { useTranslation } from 'react-i18next';
import { useFetchGroups } from '@features/groups/hooks';
import { Header } from '@components';
import { useAuth, useGetUserSubscription, usePrevious } from '@hooks';
import { AppSideNav } from '../AppSideNav/AppSideNav';
import { RootState, useAppDispatch } from '../../../../store';
import './DashboardLayout.scss';

const b = block('DashboardLayout');

interface DashboardLayoutProps {
  children?: React.ReactNode;
  isNoRedirect?: boolean;
}

export const DashboardLayout: React.FC<DashboardLayoutProps> = (props) => {
  const { children, isNoRedirect } = props;
  const { appId, category } = useParams<{
    appId: string;
    category?: Templates.Category;
  }>();
  const userState = useSelector((store: RootState) => store.user);
  const app = useSelector((store: RootState) => store.appManager);
  const [isShowedNotification, setIsShowedNotification] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isConfirmOpen, setIsConfrimOpen] = useState(false);
  const groups = useSelector((store: RootState) => store.groups);
  const onboardings = useSelector((store: RootState) => store.onboardings);
  const [isShouldRedirect, setIsShouldRedirect] = useState(false);

  const { t } = useTranslation();

  useFetchProduct();

  const editGroup = useSelector((store: RootState) => store.groups.editGroup);

  useEffect(() => {
    if (
      userState.status === 'loaded' &&
      userState.user.email &&
      !userState.user.isEmailConfirmed &&
      isShowedNotification
    ) {
      setIsConfrimOpen(true);
    }
  }, [userState]);

  useAuth();
  useFetchApps(!appId && !isNoRedirect);
  useSelectApp(appId);
  useFetchGroups({
    appId,
    type: category === Templates.Category.ONBOARDING ? GroupsType.ONBOARDING : GroupsType.GROUP
  });

  const userSubcription = useGetUserSubscription();
  const prevAppId = usePrevious(appId);

  useEffect(() => {
    if (!isShouldRedirect) {
      setIsShouldRedirect(prevAppId !== appId);
    }
  }, [prevAppId, appId]);

  useEffect(() => {
    if (!isShouldRedirect) {
      return;
    }

    if (
      location.pathname.includes('stories') &&
      groups.status === 'loaded' &&
      !groups.groups.length
    ) {
      setIsShouldRedirect(false);
      history.push(`/dashboard/${appId}/onboarding`);
    } else if (
      location.pathname.includes('onboarding') &&
      onboardings.status === 'loaded' &&
      !onboardings.onboardings.length
    ) {
      setIsShouldRedirect(false);
      history.push(`/dashboard/${appId}/stories`);
    }
  }, [isShouldRedirect, groups, onboardings]);

  useEffect(() => {
    if (!userSubcription.isLoaded) return;

    if (userSubcription.isUnpaid && !isShowedNotification) {
      dispatch(
        informSlice.actions.addMessage({
          type: MessageTypes.NEED_ACTION,
          text: t('notification.subscription.billingIssue'),
          action: {
            text: t('user.updatePaymentMethod'),
            link: `${process.env.REACT_APP_STRIPE_BILLING_LINK}?prefilled_email=${userState.user.email}`,
            isExternal: true
          }
        })
      );

      setIsShowedNotification(true);
    }
  }, [userSubcription]);

  return (
    <div className={b()}>
      <Header
        className={`${b('header')}`}
        leftNav={app.current ? <ChooseAppContainer /> : undefined}
        withLogo={false}
      />

      <AppSideNav className={`${b('side-nav')}`} isLoading={!app.current} />

      <section className={b('content')}>
        {app.appsList.length === 0 && !app.current && app.status === 'loaded' ? (
          <AddAppPage />
        ) : (
          children
        )}
      </section>
      <ConfirmEmailModal isOpen={isConfirmOpen} onClose={() => setIsConfrimOpen(false)} />
      <EditGroupModal
        group={editGroup}
        isOpen={!!editGroup}
        onClose={() => dispatch(groupsSlice.actions.setEditGroup(undefined))}
      />
    </div>
  );
};
