import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { AppPlan, UserRole, UserSubscriptionStatus } from '@features/user/types';
import { useGetCurrentApp } from '@features/stories/hooks';
import { useEffect, useState } from 'react';
import { DEFAULT_MAU_LIMIT } from '@features';

interface UserSubscription {
  userId: string;
  userEmail: string;
  subscriptionId: string;
  plan: AppPlan | string;
  isTrialing: boolean;
  isSubscribed: boolean;
  isUnpaid: boolean;
  isLoaded: boolean;
  isConfirmed: boolean;
  isAppOwner: boolean;
  subscriptionStatus?: UserSubscriptionStatus;
  mauLimit: number;
  mauUsage: number;
  priceId: string;
  price: number;
  currency: string;
  subscriptionStart: number | null;
  subscriptionFinish: number | null;
}

export const useGetUserSubscription = () => {
  const user = useSelector((store: RootState) => store.user.user);
  const products = useSelector((store: RootState) => store.products.products);
  const currentApp = useGetCurrentApp();

  const [userSubcription, setUserSubcription] = useState<UserSubscription>({
    userId: '',
    userEmail: '',
    subscriptionId: '',
    plan: AppPlan.MAU,
    isTrialing: false,
    isConfirmed: false,
    isSubscribed: false,
    isUnpaid: false,
    isLoaded: false,
    isAppOwner: false,
    subscriptionStatus: UserSubscriptionStatus.ACTIVE,
    mauLimit: DEFAULT_MAU_LIMIT,
    mauUsage: 0,
    priceId: '',
    price: 0,
    currency: 'usd',
    subscriptionStart: null,
    subscriptionFinish: null
  });

  useEffect(() => {
    if (user.id && user.role === UserRole.ADMIN) {
      setUserSubcription({
        userId: user.id,
        userEmail: user.email ?? '',
        subscriptionId: user.subscriptionId || '',
        plan: AppPlan.MAU,
        isTrialing: false,
        isSubscribed: true,
        isConfirmed: true,
        isUnpaid: false,
        isLoaded: true,
        isAppOwner: currentApp?.ownerId === user.id,
        subscriptionStatus: UserSubscriptionStatus.ACTIVE,
        mauLimit: user.mauLimit,
        price: products.length
          ? products[0].tiers?.find((tier) => tier.upTo === user.mauLimit)?.flatAmount || 0
          : 0,
        currency: 'usd',
        mauUsage: user.mauUsage,
        priceId: user.priceId,
        subscriptionStart: user.subscriptionStart,
        subscriptionFinish: user.subscriptionFinish
      });
    } else if (user.id && products.length) {
      const isUnpaid =
        user.subscriptionStatus === UserSubscriptionStatus.INCOMPLETE ||
        user.subscriptionStatus === UserSubscriptionStatus.UNPAID ||
        user.subscriptionStatus === UserSubscriptionStatus.INCOMPLETE_EXPIRED ||
        user.subscriptionStatus === UserSubscriptionStatus.PAST_DUE;

      setUserSubcription({
        userId: user.id,
        userEmail: user.email ?? '',
        plan: AppPlan.MAU,
        isConfirmed: user.isEmailConfirmed,
        subscriptionId: user?.subscriptionId || '',
        isUnpaid,
        mauLimit: user.mauLimit,
        mauUsage: user.mauUsage,
        priceId: user.priceId,
        subscriptionStart: user.subscriptionStart,
        price: products[0].tiers?.find((tier) => tier.upTo === user.mauLimit)?.flatAmount || 0,
        currency: 'usd',
        isSubscribed:
          user.subscriptionStatus === UserSubscriptionStatus.TRIALING ||
          user.subscriptionStatus === UserSubscriptionStatus.ACTIVE,
        isLoaded: true,
        isAppOwner: currentApp?.ownerId === user.id,
        subscriptionStatus: user?.subscriptionStatus,
        isTrialing: user.subscriptionStatus === UserSubscriptionStatus.TRIALING,
        subscriptionFinish: user.subscriptionFinish
      });
    }
  }, [user, currentApp, products]);

  return userSubcription;
};
