import React, { useEffect, useState } from 'react';
import { API } from '@services';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { PieChart } from '@components';
import { useIsMobile } from '@hooks';

import './AnalyticsAppsStat.scss';

interface AnalyticsAppsStatProps {
  dateFrom: string;
  dateTo: string;
}

export const AnalyticsAppsStat: React.FC<AnalyticsAppsStatProps> = ({ dateFrom, dateTo }) => {
  const [appsStats, setAppsStats] = useState<{ label: string; value: number; id: string }[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useIsMobile();
  const appManager = useSelector((store: RootState) => store.appManager);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const { data } = await API.analytics.getReport({
        unit: 'app',
        dateFrom,
        dateTo
      });

      if (data.data && !data.error) {
        setAppsStats(
          data.data?.map((item: any) => ({
            id: item.value,
            label:
              appManager.appsList.find((app) => app.id === item.value)?.title ??
              t('dashboard.analytics.unknownApp'),
            value: item.total
          })) ?? []
        );
      }

      setIsLoading(false);
    };

    if (dateFrom && dateTo && appManager.status === 'loaded') {
      fetchData();
    }
  }, [appManager, dateFrom, dateTo]);

  useEffect(() => {
    if (!appsStats.length && !isLoading) {
      setAppsStats([
        {
          label: t('dashboard.analytics.allApps'),
          value: 1,
          id: 'all'
        }
      ]);
    }
  }, [appsStats, isLoading]);

  return (
    <PieChart
      data={appsStats}
      height={isMobile ? 130 : 200}
      isLoading={isLoading}
      width={isMobile ? 130 : 200}
    />
  );
};
