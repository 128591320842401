import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { AppSideNav } from '@modules/DashboardModule/components/AppSideNav/AppSideNav';
import { useTranslation } from 'react-i18next';
import { useFetchApps, useFetchProduct, useSelectApp } from '@features';
import { Header, NavButton } from '@components';
import { useAuth } from '@hooks';
import { RootState } from '../../../../store';
import './AccountModuleLayout.scss';

const b = block('AccountModuleLayout');

export const AccountModuleLayout: React.FC = (props) => {
  const { children } = props;
  const app = useSelector((store: RootState) => store.appManager);

  useAuth();
  useFetchApps();
  useFetchProduct();
  useSelectApp();

  const { t } = useTranslation();

  return (
    <div
      className={b({
        noNav: !app.current
      })}
    >
      <Header
        className={`${b('header')}`}
        leftNav={<NavButton link="/dashboard" text={t('dashboard.header.backToProjects')} />}
        withLogo={false}
      />

      {app.current && <AppSideNav className={`${b('side-nav')}`} />}

      <section className={b('content')}>{children}</section>
    </div>
  );
};
