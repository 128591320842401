import React, { useMemo } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGetUserSubscription } from '@hooks';
import { IconInfoSuccess, IconInfoWarning } from '@components/Icon';
import { shortNumber, shortNumberWithComma } from '@utils';
import './HeaderSubscription.scss';

const b = block('HeaderSubscription');

interface HeaderSubscriptionProps {
  isWarning?: boolean;
}

export const HeaderSubscription: React.FC<HeaderSubscriptionProps> = ({ isWarning }) => {
  const userSubcription = useGetUserSubscription();
  const { t } = useTranslation();
  const history = useHistory();

  const progressPercent = useMemo(() => {
    const value =
      (userSubcription.mauLimit ? userSubcription.mauUsage / userSubcription.mauLimit : 0) * 100;

    if (value > 100) {
      return 100;
    }

    return value;
  }, [userSubcription.mauLimit, userSubcription.mauUsage]);

  const getIcon = () => {
    if (isWarning) {
      return <IconInfoWarning />;
    }

    return <IconInfoSuccess />;
  };

  return (
    <div
      className={b()}
      onClick={() => {
        history.push('/account/usage');
      }}
    >
      {getIcon()}

      <div className={b('container')}>
        <p className={b('mau')}>
          {shortNumberWithComma(userSubcription.mauUsage)}/
          {userSubcription.mauLimit ? shortNumber(userSubcription.mauLimit) : '∞'}{' '}
          {t('dashboard.usage.mau')}
        </p>

        {userSubcription.mauLimit > 0 && (
          <div className={b('progress')}>
            <div
              className={b('progressBar')}
              style={{
                width: `${progressPercent}%`
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
