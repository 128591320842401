import React, { useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { IconSelectArrowFresh } from '@components';
import './MonthPicker.scss';

const b = block('MonthPicker');

enum Month {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12
}

interface MonthPickerProps {
  initMonth?: Month;
  onChange: (month: Month) => void;
}

export const MonthPicker: React.FC<MonthPickerProps> = ({
  initMonth = Month.January,
  onChange
}) => {
  const [month, setMonth] = useState(initMonth);

  const { t } = useTranslation();

  const currentMonthNumber = useMemo(() => DateTime.now().month, []);
  const prevMonth = useMemo(() => (month - 1 > 0 ? month - 1 : 1), [month]);
  const nextMonth = useMemo(() => (month + 1 < 13 + 1 ? month + 1 : 12), [month]);

  const handleChange = (newMonth: Month) => {
    setMonth(newMonth);
  };

  useEffect(() => {
    setMonth(initMonth);
  }, [initMonth]);

  useEffect(() => {
    onChange(month);
  }, [month]);

  return (
    <div className={b()}>
      <button className={b('button')} onClick={() => handleChange(prevMonth)}>
        <IconSelectArrowFresh
          className={b('arrow', {
            prev: true
          }).toString()}
        />
      </button>

      <span className={b('month')}>{t(`monthes.${month}`)}</span>

      <button
        className={b('button')}
        onClick={nextMonth <= currentMonthNumber ? () => handleChange(nextMonth) : undefined}
      >
        <IconSelectArrowFresh
          className={b('arrow', {
            next: true
          }).toString()}
        />
      </button>
    </div>
  );
};
