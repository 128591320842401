import React from 'react';
import block from 'bem-cn';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@components';
import './AnalyticsIndicator.scss';

interface AnalyticsIndicatorProps {
  id: string;
  title: string;
  value: number;
  isLoading?: boolean;
  tooltipText?: string;
}

const b = block('AnalyticsIndicator');

export const AnalyticsIndicator: React.FC<AnalyticsIndicatorProps> = ({
  id,
  title,
  value,
  tooltipText,
  isLoading
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip id={`analytics-indicator-${id}`} text={tooltipText ?? ''}>
      <div className={b()} id={`analytics-indicator-${id}`}>
        <div className={b('title')}>{title}</div>
        {isLoading ? (
          <Skeleton height={32} width={100} />
        ) : (
          <div className={b('value')}>{value}</div>
        )}
      </div>
    </Tooltip>
  );
};
