import React from 'react';
import block from 'bem-cn';
import Skeleton from 'react-loading-skeleton';
import './AnalyticsPercentageTable.scss';

interface AnalyticsPercentageTableProps {
  title: string;
  isLoading?: boolean;
  items: {
    icon?: React.ReactNode;
    title: string;
    value: number;
  }[];
}

const b = block('AnalyticsPercentageTable');

export const AnalyticsPercentageTable: React.FC<AnalyticsPercentageTableProps> = ({
  title,
  items,
  isLoading
}) => {
  const totalValue = items?.reduce((acc, item) => acc + item.value, 0);

  return (
    <div className={b()}>
      {isLoading ? (
        <>
          <div className={b('title')}>
            <Skeleton width={100} />
          </div>
          <div className={b('items')}>
            {Array.from({ length: 3 }).map((_, index) => (
              <div className={b('item')} key={index}>
                <div className={b('titleContainer')}>
                  <Skeleton height={44} width={44} />

                  <div className={b('itemTitle')}>
                    <Skeleton width={100} />
                  </div>
                </div>
                <div className={b('value')}>
                  <Skeleton width={50} />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className={b('title')}>{title}</div>
          <div className={b('items')}>
            {items
              ?.sort((a, b) => b.value - a.value)
              .map((item, index) => (
                <div className={b('item')} key={index}>
                  <div className={b('titleContainer')}>
                    {item.icon && <div className={b('icon')}>{item.icon}</div>}

                    <div className={b('itemTitle')}>{item.title}</div>
                  </div>
                  <div className={b('value')}>
                    {totalValue === 0 ? '0%' : `${((item.value / totalValue) * 100).toFixed(0)}%`}
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
