import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API } from '@services';
import { RootState } from '@store';
import { State, UserRole, UserSubscriptionStatus } from './types';
import { DEFAULT_MAU_LIMIT } from './consts';

const initialState: State = {
  user: {
    id: null,
    name: null,
    email: null,
    isEmailConfirmed: false,
    locale: 'en',
    role: UserRole.MEMBER,
    mauLimit: DEFAULT_MAU_LIMIT,
    mauUsage: 0,
    priceId: '',
    subscriptionId: '',
    subscriptionStart: null,
    subscriptionFinish: null,
    subscriptionStatus: UserSubscriptionStatus.ACTIVE
  },
  uiLocales: [
    {
      id: 'en',
      name: 'English'
    }
  ],
  status: 'idle'
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: () => initialState,
    init: (state, action: PayloadAction<State>) => {
      state = action.payload;
    },
    setUser: (state, action: PayloadAction<State['user']>) => {
      state.user = action.payload;
      state.status = 'loaded';
    },
    setName: (state, action: PayloadAction<string>) => {
      state.user.name = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.user.email = action.payload;
    },
    setEmailConfirmStatus: (state, action: PayloadAction<boolean>) => {
      state.user.isEmailConfirmed = action.payload;
    },
    setUserLocale: (state, action: PayloadAction<string>) => {
      state.user.locale = action.payload;
    },
    setStatus: (state, action: PayloadAction<State['status']>) => {
      state.status = action.payload;
    },
    setUserMauLimit: (state, action: PayloadAction<number>) => {
      state.user.mauLimit = action.payload;
    }
  }
});

export const fetchInitUser = createAsyncThunk(
  'user/fetchInitUser',
  async (params, { dispatch, getState }) => {
    dispatch(userSlice.actions.setStatus('loading'));

    const { data } = await API.user.init();

    if (data) {
      const state = getState() as RootState;

      const user = {
        id: data.id,
        name: data.full_name,
        email: data.email,
        isEmailConfirmed: data.confirm,
        locale: state.user?.user?.locale ?? 'en',
        role: data.role ?? UserRole.MEMBER,
        mauLimit: data.mau_limit ?? DEFAULT_MAU_LIMIT,
        mauUsage: data.mau_usage ?? 0,
        priceId: data.price_id,
        subscriptionId: data.subscription_id,
        subscriptionStart: data.subscription_start,
        subscriptionFinish: data.subscription_finish,
        subscriptionStatus: data.subscription_status ?? UserSubscriptionStatus.ACTIVE
      };

      dispatch(userSlice.actions.setUser(user));
    } else {
      dispatch(userSlice.actions.setStatus('loaded'));
    }
  }
);

export default userSlice;
