import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  AppCollaborationFeatures,
  fetchChangeTemplateStoriesLayerVisibility,
  fetchCreateTemplateStory,
  fetchRemoveTemplateLayersGroup,
  fetchRemoveTemplateStory,
  fetchUpdateAllGroupTemplateStoriesStatus,
  fetchUpdateTemplateLayersGroupStatus,
  fetchUpdateTemplateStoryBackground,
  getInteractiveWidgetIds,
  GroupsType,
  SettingsButton,
  SettingsDropdownButton,
  SettingsOpenLayerSelect,
  SIZE_PRESETS,
  SizePresetKeys,
  Templates,
  useGetAccessByCollaboratorRole,
  useGetActionByAccess,
  useGetActionByCollaboratorRole
} from '@features';
import { RootState, useAppDispatch } from '@store';
import {
  ColorPicker,
  SettingsBox,
  StoryEditorAction,
  useStoryEditor,
  useStoryEditorDispatch
} from '@modules';
import {
  fetchCreateStory,
  fetchUpdateStoryBackground,
  fetchRemoveLayersGroup,
  fetchUpdateLayersGroupStatus,
  fetchRemoveStory,
  fetchChangeStoriesLayerVisibility,
  storiesSlice,
  fetchUpdateAllGroupStoriesStatus
} from '@features/stories/storiesSlice';
import { nanoid } from 'nanoid';
import { BackgroundBlob, StoryStatus } from '@features/stories/types';
import { MessageTypes, informSlice } from '@features/inform';
import { onUploadProgress } from '@features/inform/utils';
import { useTranslation } from 'react-i18next';
import {
  useCurrentStoriesSlice,
  useCurrentStoriesType,
  useGetCurrentGroup,
  useIsDefaultLanguageChoosen
} from '@features/stories/hooks';
import { useGroupPublish } from '@features/groups/hooks';
import { COLOR_DEFAULT } from '@modules/EditorModule/widgets/_components/ColorPicker/constants/colorPickerStyles';
import block from 'bem-cn';
import { BackgroundColorType, BackgroundType, MediaType } from '@storysdk/react';
import { AppFeatures } from '@features/user/consts';
import { DateTime } from 'luxon';
import { Icon, SwitchGroup } from '@components';
import { getActiveStoriesFlattenedArr, getInitStoryParams } from '@utils';
import { SettingsSelect } from '../../editorSettings/components/SettingsSelect/SettingsSelect';
import { ControlGroup } from '../../editorSettings/components/ControlGroup/ControlGroup';
import { SettingsInput } from '../../editorSettings/components/SettingsInput/SettingsInput';
import './StoryDesignTools.scss';

const b = block('StoryDesignTools');

export const StoryDesignTools: React.FC = () => {
  const { appId, groupId, templateId, type, category } = useParams<{
    appId?: string;
    groupId?: string;
    templateId?: string;
    type: GroupsType;
    category?: Templates.Category;
  }>();
  const dispatch = useAppDispatch();

  const currentStoriesType = useCurrentStoriesType(type);
  const currentStoriesSlice = useCurrentStoriesSlice(type);

  const storyEditorState = useStoryEditor();
  const storyEditorDispatch = useStoryEditorDispatch();

  const stories = useSelector((store: RootState) => store[currentStoriesType].stories);
  const selectedLayersGroupId =
    useSelector((store: RootState) => store[currentStoriesType].editor.selectedLayersGroupId) ?? '';
  const selectedLayerGroup = useSelector(
    (store: RootState) => store[currentStoriesType].stories[selectedLayersGroupId]
  );
  const creatingStoryStatus = useSelector(
    (store: RootState) => store[currentStoriesType].creatingStatus
  );
  const storiesLoadingStatus = useSelector((store: RootState) => store[currentStoriesType].status);

  const selectedStoryId = selectedLayerGroup.activeLayerId;
  const selectedStory = storyEditorState?.story;

  const isOnDefaultLocale = useIsDefaultLanguageChoosen();
  const app = useSelector((store: RootState) => store.appManager);
  const isActive = app.currentLocale && !selectedStory?.storyData[app.currentLocale].is_hidden;
  const isPublished =
    app.currentLocale &&
    selectedStory?.storyData[app.currentLocale].status === StoryStatus.ACTIVE &&
    !selectedStory?.isHasUnpublishedChanges;

  const currentGroup = useGetCurrentGroup(type, templateId ?? groupId, !!templateId);
  const currentSizePreset = useMemo(
    () => currentGroup?.settings?.sizePreset ?? SizePresetKeys.IphoneSmall,
    [currentGroup?.settings?.sizePreset]
  );

  const positionsValues = getActiveStoriesFlattenedArr(stories).map((_, index) => ({
    value: index + 1,
    title: `#${index + 1}`
  }));

  const interactiveWidgetIds = getInteractiveWidgetIds(selectedStory?.widgets ?? []);

  let lastLayersGroupPosition = 0;

  Object.values(stories).forEach((story) => {
    if (story.position > lastLayersGroupPosition) {
      lastLayersGroupPosition = story.position;
    }
  });

  const { t } = useTranslation();

  useGroupPublish(appId, groupId, type, storiesLoadingStatus === 'loaded');
  const onActionClick = useGetActionByAccess();
  const onActionClickCollaborator = useGetActionByCollaboratorRole();

  const isDeleteAvailable = useGetAccessByCollaboratorRole({
    feature: AppCollaborationFeatures.REMOVE_STORIES
  });

  const isPublishAvailable = useGetAccessByCollaboratorRole({
    feature: AppCollaborationFeatures.PUBLISH_STORIES
  });

  const handleChangeBackground = (background: BackgroundBlob | BackgroundType): void => {
    if (background.type === 'video' || background.type === 'image') {
      const newModalId = nanoid(6);

      dispatch(
        informSlice.actions.addMessage({
          id: newModalId,
          type: MessageTypes.PROGRESS,
          // @ts-ignore
          text: background.value.name
        })
      );

      if (type === GroupsType.TEMPLATE && templateId) {
        dispatch(
          fetchUpdateTemplateStoryBackground({
            storyId: selectedStoryId,
            layersGroupId: selectedLayersGroupId,
            background: background as BackgroundBlob,
            onUploadProgress: onUploadProgress(dispatch),
            modalProgressId: newModalId
          })
        );
      } else {
        dispatch(
          fetchUpdateStoryBackground({
            storyId: selectedStoryId,
            layersGroupId: selectedLayersGroupId,
            background: background as BackgroundBlob,
            onUploadProgress: onUploadProgress(dispatch),
            modalProgressId: newModalId
          })
        );
      }
    } else {
      storyEditorDispatch?.({
        type: StoryEditorAction.UPDATE_STORY_BACKGROUND,
        payload: {
          background: {
            ...background,
            isFilled: selectedStory?.background.isFilled ?? false,
            fileId: ''
          }
        }
      });
    }
  };

  // const handleChangeBackgroundFilled = (isFilled: boolean) => {
  //   storyEditorState?.story &&
  //     storyEditorDispatch?.({
  //       type: StoryEditorAction.UPDATE_STORY_BACKGROUND,
  //       payload: {
  //         background: {
  //           ...storyEditorState.story.background,
  //           isFilled
  //         }
  //       }
  //     });
  // };

  const handleRemoveLayersGroup = () => {
    if (type === GroupsType.TEMPLATE && templateId) {
      dispatch(
        fetchRemoveTemplateLayersGroup({
          templateId,
          layersGroupId: selectedLayersGroupId
        })
      );
    } else if (appId && groupId) {
      dispatch(
        fetchRemoveLayersGroup({
          appId,
          groupId,
          layersGroupId: selectedLayersGroupId
        })
      );
    }
  };

  const handleChangePosition = (position: number) => {
    dispatch(currentStoriesSlice.actions.setPosition(position));
  };

  const handleCopyLayersGroup = () => {
    const layerData = {
      layersGroupId: nanoid(),
      score: {
        letter: 'A',
        points: 0
      }
    };

    for (const layerId in stories[selectedLayersGroupId].layers) {
      if (stories[selectedLayersGroupId].layers[layerId] && app.currentLocale) {
        const newWidgets = stories[selectedLayersGroupId].layers[layerId].widgets.map((widget) => ({
          ...widget,
          id: `W${nanoid()}`
        }));

        const storyParams = {
          ...stories[selectedLayersGroupId].layers[layerId],
          widgets: newWidgets,
          storyData: {
            ...stories[selectedLayersGroupId].layers[layerId].storyData,
            [app.currentLocale]: {
              ...stories[selectedLayersGroupId].layers[layerId].storyData[app.currentLocale],
              widgets: newWidgets,
              background: stories[selectedLayersGroupId].layers[layerId].background
            }
          },
          layerData: {
            ...layerData,
            isDefaultLayer: stories[selectedLayersGroupId].layers[layerId].layerData.isDefaultLayer,
            positionInGroup:
              stories[selectedLayersGroupId].layers[layerId].layerData.positionInGroup,
            isWidgetsSizeSyncActive:
              stories[selectedLayersGroupId].layers[layerId].layerData.isWidgetsSizeSyncActive
          },
          position: lastLayersGroupPosition + 1
        };

        if (type === GroupsType.TEMPLATE && templateId) {
          dispatch(
            fetchCreateTemplateStory({
              templateId,
              story: storyParams
            })
          );
        } else if (appId && groupId) {
          dispatch(
            fetchCreateStory({
              appId,
              groupId,
              type,
              story: storyParams
            })
          );
        }
      }
    }
  };

  const handleChangeVisibility = (isHidden: boolean) => {
    if (type === GroupsType.TEMPLATE && templateId) {
      dispatch(
        fetchChangeTemplateStoriesLayerVisibility({
          templateId,
          layersGroupId: selectedLayersGroupId,
          isHidden
        })
      );
    } else if (appId && groupId) {
      dispatch(
        fetchChangeStoriesLayerVisibility({
          appId,
          groupId,
          layersGroupId: selectedLayersGroupId,
          isHidden
        })
      );
    }
  };

  const handleChangeLocalesSync = (isSyncDesignActive: boolean) => {
    if (
      appId &&
      groupId &&
      selectedStoryId &&
      selectedLayersGroupId &&
      storyEditorState?.story &&
      app.current &&
      app.currentLocale
    ) {
      const currentStoryData =
        storyEditorState.story.storyData[app.current.appLocalization.default].history.story_data ??
        storyEditorState.story.storyData[app.current.appLocalization.default];

      if (isSyncDesignActive && currentStoryData) {
        storyEditorDispatch?.({
          type: StoryEditorAction.UPDATE_STORY,
          payload: {
            widgets: currentStoryData.widgets,
            background: currentStoryData.background,
            storyData: {
              ...storyEditorState.story.storyData,
              [app.currentLocale]: {
                ...storyEditorState.story.storyData[app.currentLocale],
                is_sync_design_active: isSyncDesignActive
              }
            }
          }
        });
      } else {
        storyEditorDispatch?.({
          type: StoryEditorAction.UPDATE_STORY,
          payload: {
            storyData: {
              ...storyEditorState.story.storyData,
              [app.currentLocale]: {
                ...storyEditorState.story.storyData[app.currentLocale],
                is_sync_design_active: isSyncDesignActive
              }
            }
          }
        });
      }
    }
  };

  const handleChangeSatus = (status: StoryStatus) => {
    if (type === GroupsType.TEMPLATE && templateId) {
      dispatch(
        fetchUpdateTemplateLayersGroupStatus({
          templateId,
          layersGroupId: selectedLayersGroupId,
          status
        })
      );
    } else if (appId && groupId) {
      dispatch(
        fetchUpdateLayersGroupStatus({
          appId,
          groupId,
          layersGroupId: selectedLayersGroupId,
          status,
          noNotification: status === StoryStatus.ACTIVE
        })
      );

      if (status === StoryStatus.ACTIVE) {
        dispatch(storiesSlice.actions.setShareStoryId(selectedStoryId));
      }
    }

    if (status === StoryStatus.ACTIVE) {
      dispatch(currentStoriesSlice.actions.setLastPublishedStoryId(selectedStoryId));
    }
  };

  const handleChangeSatusToAllStories = (status: StoryStatus) => {
    if (appId && groupId) {
      dispatch(
        fetchUpdateAllGroupStoriesStatus({
          appId,
          groupId,
          status
        })
      );
    } else if (templateId) {
      dispatch(
        fetchUpdateAllGroupTemplateStoriesStatus({
          templateId,
          status
        })
      );
    }
  };

  let lastLayerInGroupPosition = 0;

  const layers = Object.values(selectedLayerGroup.layers)
    .sort((layerA, layerB) => layerA.layerData.positionInGroup - layerB.layerData.positionInGroup)
    .map((layer) => {
      if (layer.layerData.positionInGroup > lastLayerInGroupPosition) {
        lastLayerInGroupPosition = layer.layerData.positionInGroup;
      }

      return {
        value: layer.id,
        title: `${t('editor.layer')} #${layer.layerData.positionInGroup} ${layer.layerData.isDefaultLayer ? `(${t('editor.default')})` : ''
          }`
      };
    });

  const handleAddLayer = () => {
    if (creatingStoryStatus !== 'creating') {
      if (type === GroupsType.TEMPLATE && templateId) {
        dispatch(
          fetchCreateTemplateStory({
            templateId,
            story: getInitStoryParams({
              position: stories[selectedLayersGroupId].position,
              layerData: {
                ...stories[selectedLayersGroupId].layers[selectedStoryId].layerData,
                positionInGroup: lastLayerInGroupPosition + 1,
                isActiveLayerInEditor: true,
                isDefaultLayer: false,
                isWidgetsSizeSyncActive: true
              }
            })
          })
        );
      } else if (appId && groupId) {
        dispatch(
          fetchCreateStory({
            appId,
            groupId,
            type,
            story: getInitStoryParams({
              position: stories[selectedLayersGroupId].position,
              layerData: {
                ...stories[selectedLayersGroupId].layers[selectedStoryId].layerData,
                positionInGroup: lastLayerInGroupPosition + 1,
                isActiveLayerInEditor: true,
                isDefaultLayer: false,
                isWidgetsSizeSyncActive: true
              }
            })
          })
        );
      }
    }
  };

  const handleChangeLayer = (id: string) => {
    dispatch(currentStoriesSlice.actions.setActiveLayerId(id));
  };

  const handleChangeDefaultLayerId = (id: string) => {
    dispatch(currentStoriesSlice.actions.setDefaultLayerId(id));
  };

  const handleRemoveLayer = (id: string) => {
    const newDefaultId = layers.find((layer) => layer.value !== id)?.value;
    if (selectedLayerGroup.defaultLayerId === id && newDefaultId) {
      dispatch(currentStoriesSlice.actions.setDefaultLayerId(newDefaultId));
    }

    if (type === GroupsType.TEMPLATE && templateId) {
      dispatch(
        fetchRemoveTemplateStory({
          templateId,
          storyId: id,
          layersGroupId: selectedLayersGroupId
        })
      );
    } else if (appId && groupId) {
      dispatch(
        fetchRemoveStory({
          appId,
          groupId,
          storyId: id,
          layersGroupId: selectedLayersGroupId
        })
      );
    }
  };

  const handleScheduleClick = () => {
    dispatch(currentStoriesSlice.actions.setScheduleLayersGroupId(selectedLayersGroupId));
  };

  return (
    <SettingsBox withToggle={process.env.NODE_ENV !== 'production'}>
      <SettingsBox.Title>{t('editor.storySettings')}</SettingsBox.Title>
      <SettingsBox.Group isGap title={t('editor.storySize')}>
        <ControlGroup isLocked>
          <SettingsInput
            disabled
            postfix="px"
            prefix="W"
            type="number"
            value={SIZE_PRESETS[currentSizePreset].width}
          />
          <SettingsInput
            disabled
            postfix="px"
            prefix="H"
            type="number"
            value={SIZE_PRESETS[currentSizePreset].height}
          />
        </ControlGroup>
      </SettingsBox.Group>
      {process.env.NODE_ENV !== 'production' && (
        <SettingsBox.Group title={t('editor.layers')} onAction={handleAddLayer}>
          <SettingsOpenLayerSelect
            defaultId={selectedLayerGroup.defaultLayerId}
            options={layers}
            value={selectedLayerGroup.activeLayerId}
            onChange={handleChangeLayer}
            onChangeDefaultId={handleChangeDefaultLayerId}
            onRemoveLayer={handleRemoveLayer}
          />
        </SettingsBox.Group>
      )}
      {!isOnDefaultLocale && !!app.currentLocale && (
        <SettingsBox.Group>
          <SwitchGroup
            isChecked={!!selectedStory?.storyData[app.currentLocale].is_sync_design_active}
            label={t('editor.designChange')}
            onToggle={() =>
              app.currentLocale
                ? handleChangeLocalesSync(
                  !selectedStory?.storyData[app.currentLocale].is_sync_design_active
                )
                : undefined
            }
          />
        </SettingsBox.Group>
      )}
      <SettingsBox.Group title={t('editor.storyPosition')}>
        <SettingsSelect
          options={positionsValues}
          value={selectedStory?.position}
          onChange={handleChangePosition}
        />
      </SettingsBox.Group>
      <SettingsBox.Group title={t('editor.background')}>
        <ColorPicker
          availableTypes={[
            BackgroundColorType.COLOR,
            BackgroundColorType.GRADIENT,
            MediaType.IMAGE,
            MediaType.VIDEO
          ]}
          className={b('colorPicker')}
          value={selectedStory?.background ?? COLOR_DEFAULT}
          onChange={handleChangeBackground}
        />
        {/* {(currentGroup?.type === GroupsType.GROUP ||
          (type === GroupsType.TEMPLATE && category === Templates.Category.STORIES)) && (
            <SwitchGroup
              isChecked={!!selectedStory?.background.isFilled}
              label={t('editor.fillBackground')}
              onToggle={() => handleChangeBackgroundFilled(!selectedStory?.background.isFilled)}
            />
          )} */}
      </SettingsBox.Group>
      <SettingsBox.Group>
        {selectedStory?.isHasUnpublishedChanges && (
          <div className={b('publishTextContainer')}>
            <p className={b('publishText')}>{t('editor.unpublishChangesText')}</p>
            {app.currentLocale && selectedStory.storyData[app.currentLocale].history?.published_at && (
              <p className={b('publishText', { grey: true })}>
                {t('editor.lastPublishing')}{' '}
                {DateTime.fromISO(
                  selectedStory.storyData[app.currentLocale].history.published_at ?? ''
                ).toLocaleString(DateTime.DATETIME_MED)}
              </p>
            )}
          </div>
        )}
        <SettingsBox.Field>
          {isPublished ? (
            <SettingsButton
              icon={<Icon name="archive" />}
              onClick={() =>
                onActionClickCollaborator(AppCollaborationFeatures.PUBLISH_STORIES, () =>
                  !isPublished
                    ? onActionClick(AppFeatures.PUBLISH_STORIES, () => {
                      interactiveWidgetIds.length > 0
                        ? onActionClick(AppFeatures.INTERACTIVE_WIDGETS, () => {
                          handleChangeSatus(StoryStatus.ACTIVE);
                        })
                        : handleChangeSatus(StoryStatus.ACTIVE);
                    })
                    : handleChangeSatus(StoryStatus.DRAFT)
                )
              }
            >
              {t('dashboard.stories.removeToDraft')}
            </SettingsButton>
          ) : (
            <SettingsDropdownButton
              disabled={selectedStory?.background.type === BackgroundColorType.TRANSPARENT}
              icon={<Icon name="publish" />}
              options={[
                {
                  value: StoryStatus.ACTIVE,
                  title: t('dashboard.stories.publishAll')
                }
              ]}
              onChange={(value: StoryStatus) => {
                if (value === StoryStatus.ACTIVE) {
                  onActionClickCollaborator(AppCollaborationFeatures.PUBLISH_STORIES, () =>
                    onActionClick(AppFeatures.PUBLISH_STORIES, () => {
                      handleChangeSatusToAllStories(StoryStatus.ACTIVE);
                    })
                  );
                }
              }}
              onClick={() =>
                onActionClickCollaborator(AppCollaborationFeatures.PUBLISH_STORIES, () =>
                  onActionClick(AppFeatures.PUBLISH_STORIES, () => {
                    interactiveWidgetIds.length > 0
                      ? onActionClick(AppFeatures.INTERACTIVE_WIDGETS, () => {
                        handleChangeSatus(StoryStatus.ACTIVE);
                      })
                      : handleChangeSatus(StoryStatus.ACTIVE);
                  })
                )
              }
            >
              {t('dashboard.stories.publish')}
            </SettingsDropdownButton>
          )}
        </SettingsBox.Field>

        {/* {type !== GroupsType.TEMPLATE && (
          <SettingsBox.Field>
            <SettingsButton icon={<Icon name="calendar-fresh" />} onClick={handleScheduleClick}>
              {t('dashboard.stories.schedule')}
            </SettingsButton>
          </SettingsBox.Field>
        )} */}

        {isOnDefaultLocale && (
          <SettingsBox.Field>
            <SettingsButton
              icon={<Icon name="copy" />}
              onClick={() =>
                onActionClickCollaborator(AppCollaborationFeatures.CREATE_STORIES, () => {
                  onActionClick(AppFeatures.CREATE_STORIES, handleCopyLayersGroup);
                })
              }
            >
              {t('editor.duplicate')}
            </SettingsButton>
          </SettingsBox.Field>
        )}

        <SettingsBox.Field>
          {isOnDefaultLocale ? (
            <>
              {isDeleteAvailable && (
                <SettingsButton icon={<Icon name="delete" />} onClick={handleRemoveLayersGroup}>
                  {t('editor.delete')}
                </SettingsButton>
              )}
            </>
          ) : (
            <>
              {isPublishAvailable && (
                <SettingsButton
                  icon={<Icon name={isActive ? 'hide' : 'show'} />}
                  onClick={() => handleChangeVisibility(!!isActive)}
                >
                  {isActive ? t('editor.hide') : t('editor.show')}
                </SettingsButton>
              )}
            </>
          )}
        </SettingsBox.Field>
      </SettingsBox.Group>
    </SettingsBox>
  );
};
