import React, { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import block from 'bem-cn';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import Skeleton from 'react-loading-skeleton';
import {
  AppCollaborationFeatures,
  informSlice,
  MessageTypes,
  useGetAccessByCollaboratorRole
} from '@features';
import {
  Logo,
  IconDashboardAnalytics,
  IconDashboardStories,
  IconDashboardSettings,
  IconDashboardOnboarding,
  IconDashboardTemplates,
  IconIntegrations
} from '@components';
import { useGetUserSubscription } from '@hooks';
import './AppSideNav.scss';

const b = block('AppSideNav');

interface AppSideNavProps {
  isLoading?: boolean;
  onlyLogo?: boolean;
  className?: string;
}

export const AppSideNav: React.FC<AppSideNavProps> = (props) => {
  const { className, isLoading, onlyLogo } = props;
  const location = useLocation();
  const currentAppId = useSelector((store: RootState) => store.appManager.current?.id);
  const tab = location.pathname.split('/')[3];
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const userSubcription = useGetUserSubscription();
  const dispatch = useAppDispatch();

  const bodyHeight = document.body.clientHeight;

  const { t } = useTranslation();

  const isAppSettingsAvailable = useGetAccessByCollaboratorRole({
    feature: AppCollaborationFeatures.CHANGE_APP_SETTINGS
  });

  const showEmailNotification = useCallback(() => {
    dispatch(
      informSlice.actions.addMessage({
        type: MessageTypes.CONFIRM_EMAIL,
        text: t('notification.user.confirmEmail'),
        action: {
          text: t('auth.resendConfirmationBtn'),
          link: '/resendConfirmation',
          value: userSubcription.userEmail
        }
      })
    );
  }, [dispatch, t, userSubcription.userEmail]);

  return (
    <nav
      className={cn(
        b({
          open: isMenuOpen
        }).toString(),
        className
      )}
      style={{
        height: bodyHeight
      }}
    >
      <Link className={b('brand')} to="/">
        <Logo className={b('logo')} color="black" />
      </Link>

      <button
        className={b('toogleBtn', { close: isMenuOpen })}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <span className={b('toogleBtnLine')} />
      </button>

      {!onlyLogo && (
        <div>
          {isLoading ? (
            <div className={b('loadContainer')}>
              <Skeleton
                height={56}
                style={{
                  borderRadius: 16
                }}
                width="100%"
              />
              <Skeleton
                height={56}
                style={{
                  borderRadius: 16
                }}
                width="100%"
              />
              <Skeleton
                height={56}
                style={{
                  borderRadius: 16
                }}
                width="100%"
              />
              <Skeleton
                height={56}
                style={{
                  borderRadius: 16
                }}
                width="100%"
              />
              <Skeleton
                height={56}
                style={{
                  borderRadius: 16
                }}
                width="100%"
              />
            </div>
          ) : (
            <div className={b('linkContainer')}>
              <Link
                className={b('link', {
                  current: tab === 'stories'
                })}
                to={`/dashboard/${currentAppId}/stories`}
                onClick={() => setIsMenuOpen(false)}
              >
                <IconDashboardStories className={b('icon').toString()} />
                {t('dashboard.sideMenu.stories')}
              </Link>
              <Link
                className={b('link', {
                  current: tab === 'onboarding'
                })}
                to={`/dashboard/${currentAppId}/onboarding`}
                onClick={() => setIsMenuOpen(false)}
              >
                <IconDashboardOnboarding
                  className={b('icon', {
                    filled: true
                  }).toString()}
                />
                {t('dashboard.sideMenu.onboarding')}
              </Link>
              <Link
                className={b('link', {
                  current: tab === 'templates'
                })}
                to={
                  userSubcription.isConfirmed
                    ? `/dashboard/${currentAppId}/templates/stories`
                    : undefined
                }
                onClick={() => {
                  if (!userSubcription.isConfirmed) {
                    showEmailNotification();
                  }

                  setIsMenuOpen(false);
                }}
              >
                <IconDashboardTemplates
                  className={b('icon', {
                    filled: true
                  }).toString()}
                />
                {t('dashboard.sideMenu.templates')}
              </Link>
              <Link
                className={b('link', {
                  current: tab === 'analytics'
                })}
                to={
                  userSubcription.isConfirmed ? `/dashboard/${currentAppId}/analytics` : undefined
                }
                onClick={() => {
                  if (!userSubcription.isConfirmed) {
                    showEmailNotification();
                  }

                  setIsMenuOpen(false);
                }}
              >
                <IconDashboardAnalytics className={b('icon').toString()} />
                {t('dashboard.sideMenu.analytics')}
              </Link>
              {isAppSettingsAvailable && (
                <>
                  <Link
                    className={b('link', {
                      current: tab === 'integrations'
                    })}
                    to={
                      userSubcription.isConfirmed
                        ? `/dashboard/${currentAppId}/integrations`
                        : undefined
                    }
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <IconIntegrations className={b('icon').toString()} />
                    {t('dashboard.sideMenu.integrations')}
                  </Link>
                  <Link
                    className={b('link', {
                      current: tab === 'settings'
                    })}
                    to={
                      userSubcription.isConfirmed
                        ? `/dashboard/${currentAppId}/settings`
                        : undefined
                    }
                    onClick={() => {
                      if (!userSubcription.isConfirmed) {
                        showEmailNotification();
                      }

                      setIsMenuOpen(false);
                    }}
                  >
                    <IconDashboardSettings className={b('icon').toString()} />
                    {t('dashboard.sideMenu.settings')}
                  </Link>
                </>
              )}
              {/* {isAppBillingAvailable && (
                <Link
                  className={b('link', {
                    current: tab === 'billing'
                  })}
                  to={`/dashboard/${currentAppId}/billing`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  <IconDashboardCard className={b('icon', { filled: true }).toString()} />
                  {t('dashboard.sideMenu.billing')}
                </Link>
              )} */}
            </div>
          )}
        </div>
      )}
    </nav>
  );
};
