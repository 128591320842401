import React, { useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { RootState } from '@store';
import { DateTime } from 'luxon';
import { AppleStock } from '@visx/mock-data/lib/mocks/appleStock';
import { API } from '@services';
import { AnalyticsAppsStat } from '@modules/DashboardModule/components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LineChart, MonthPicker } from '@components';
import { useIsMobile } from '@hooks';
import { MauCounter } from '../MauCounter';
import './UsageTab.scss';

const b = block('UsageTab');

interface UsageTabProps {
  isActive: boolean;
}

export const UsageTab: React.FC<UsageTabProps> = ({ isActive }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();

  const user = useSelector((state: RootState) => state.user.user);
  const app = useSelector((store: RootState) => store.appManager);

  const [currentMonth, setCurrentMonth] = React.useState<number>(new Date().getMonth() + 1);
  const startOfMonth = useMemo(
    () => DateTime.fromObject({ month: currentMonth }).startOf('month').toISODate(),
    [currentMonth]
  );
  const endOfMonth = useMemo(
    () => DateTime.fromObject({ month: currentMonth }).endOf('month').toISODate(),
    [currentMonth]
  );
  const [activeUsers, setActiveUsers] = useState<AppleStock[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const chartInnerRef = React.useRef<HTMLDivElement>(null);

  const [chartInnerWidth, setChartInnerWidth] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (chartInnerRef.current) {
        setChartInnerWidth(chartInnerRef.current.offsetWidth);
      }
    });

    const currentRef = chartInnerRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isActive]);

  useEffect(() => {
    const fetchUsersData = async () => {
      setIsLoading(true);
      const { data } = await API.analytics.getUsers({
        interval: 'day',
        dateFrom: startOfMonth ?? '',
        dateTo: endOfMonth ?? ''
      });

      if (data.data && !data.error) {
        setActiveUsers(
          data.data.map((item: any) => ({
            date: item.date,
            close: item.total
          }))
        );
      } else {
        setActiveUsers([]);
      }

      setIsLoading(false);
    };

    if (startOfMonth && endOfMonth) {
      fetchUsersData();
    }
  }, [startOfMonth, endOfMonth]);

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('titleContainer')}>
          <p className={b('title')}>{t('dashboard.usage.title')}</p>
          <p className={b('desc')}>{t('dashboard.usage.monthlyActiveUsers')}</p>
        </div>
        <div className={b('monthContainer')}>
          <MonthPicker initMonth={currentMonth} onChange={setCurrentMonth} />
        </div>
      </div>

      <div className={b('content')}>
        <MauCounter
          mau={activeUsers.reduce((acc, item) => acc + item.close, 0)}
          mauLimit={user.mauLimit}
          month={currentMonth}
          onUpgradeClick={() => history.push('/account/billing')}
        />
        <div className={b('chart')}>
          <div className={b('chartTitle')}>{t('dashboard.usage.monthlyActiveUsers')}</div>
          <div className={b('chartContainer')} ref={chartInnerRef}>
            <LineChart
              data={activeUsers}
              height={isMobile ? 200 : 400}
              isLoading={isLoading || app.status === 'loading'}
              width={chartInnerWidth}
            />
          </div>
        </div>
        <div className={b('pieChart')}>
          <p className={b('pieChartTitle')}>{t('dashboard.usage.apps')}</p>
          <AnalyticsAppsStat dateFrom={startOfMonth ?? ''} dateTo={endOfMonth ?? ''} />
        </div>
      </div>
    </div>
  );
};
