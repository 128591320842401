import React from 'react';
import PropTypes from 'prop-types';

const IconStatDesktop = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="22"
    viewBox="0 0 24 22"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M16.8889 20.7775V21.2219H7.11108V20.7775C7.11108 20.6597 7.15775 20.5464 7.24108 20.463C7.32442 20.3797 7.43775 20.333 7.55554 20.333H16.4444C16.5622 20.333 16.6755 20.3797 16.7589 20.463C16.8422 20.5463 16.8889 20.6597 16.8889 20.7775Z"
      fill="black"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M9.67998 17.7783H14.3198L14.9865 20.445H9.01318L9.67998 17.7783Z"
      fill="black"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M24 1.33333V15.5556C24 16.2922 23.4033 16.8889 22.6667 16.8889H1.33333C0.596679 16.8889 0 16.2922 0 15.5556V1.33333C0 0.596679 0.596679 0 1.33333 0H22.6667C23.4033 0 24 0.596679 24 1.33333ZM10.2221 15.9999H13.7776C14.0232 15.9999 14.2221 15.801 14.2221 15.5554C14.2221 15.3099 14.0232 15.111 13.7776 15.111H10.2221C9.97653 15.111 9.77764 15.3099 9.77764 15.5554C9.77764 15.801 9.97653 15.9999 10.2221 15.9999ZM23.1108 1.33333C23.1108 1.08778 22.9119 0.888889 22.6664 0.888889H1.33305C1.08749 0.888889 0.888604 1.08778 0.888604 1.33333V13.7778C0.888604 14.0233 1.08749 14.2222 1.33305 14.2222H22.6664C22.9119 14.2222 23.1108 14.0233 23.1108 13.7778V1.33333Z"
      fill="black"
      fillRule="evenodd"
    />
  </svg>
);

IconStatDesktop.propTypes = {
  className: PropTypes.string
};

IconStatDesktop.defaultProps = {
  className: ''
};

export default IconStatDesktop;
