import React, { useMemo, useCallback } from 'react';
import { AreaClosed, Line, Bar } from '@visx/shape';
import { AppleStock } from '@visx/mock-data/lib/mocks/appleStock';
import { curveMonotoneX } from '@visx/curve';
import { Group } from '@visx/group';
import { GridRows } from '@visx/grid';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { scaleTime, scaleLinear } from '@visx/scale';
import { withTooltip, Tooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import { max, extent, bisector } from '@visx/vendor/d3-array';
import { timeFormat } from '@visx/vendor/d3-time-format';
import Skeleton from 'react-loading-skeleton';

type TooltipData = AppleStock;

export const background = '#FFFFFF';
export const background2 = '#204051';
export const accentColor = '#FD19CC';
export const accentColorDark = '#FD19CC';
export const columsColor = '#E9E6E9';
export const tooltipBackground = '#05051D';
const tooltipStyles = {
  ...defaultStyles,
  background: tooltipBackground,
  borderRadius: 4,
  color: 'white',
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.10)'
};

const axisColor = '#A49BA4';
const axisBottomTickLabelProps = {
  textAnchor: 'middle' as const,
  fontFamily: 'Arial',
  fontSize: 10,
  fill: axisColor
};
const axisLeftTickLabelProps = {
  dx: '-0.25em',
  dy: '0.25em',
  fontFamily: 'Arial',
  fontSize: 10,
  textAnchor: 'end' as const,
  fill: axisColor
};

const AXIS_WIDTH = 30;
const AXIS_HEIGHT = 10;

const formatDate = timeFormat("%b %d, '%y");

const getDate = (d: AppleStock) => new Date(d.date);
const getStockValue = (d: AppleStock) => d?.close;
const bisectDate = bisector<AppleStock, Date>((d) => new Date(d.date)).left;

export type AreaProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  hideBottomAxis?: boolean;
  hideLeftAxis?: boolean;
  data: AppleStock[];
  isLoading?: boolean;
};

export const LineChart = withTooltip<AreaProps, TooltipData>(
  ({
    width,
    height,
    margin = { top: 0, right: 0, bottom: 0, left: 0 },
    showTooltip,
    hideTooltip,
    hideBottomAxis,
    hideLeftAxis,
    tooltipData,
    data,
    tooltipTop = 0,
    tooltipLeft = 0,
    isLoading
  }: AreaProps & WithTooltipProvidedProps<TooltipData>) => {
    if (width < 10) return null;

    if (isLoading) {
      return (
        <Skeleton
          height={height}
          style={{
            borderRadius: 20
          }}
          width={width}
        />
      );
    }

    const innerWidth = width - margin.left - margin.right - (!hideLeftAxis ? AXIS_WIDTH : 0);
    const innerHeight = height - margin.top - margin.bottom - (!hideBottomAxis ? AXIS_HEIGHT : 0);

    const dateScale = useMemo(
      () =>
        scaleTime({
          range: [margin.left, innerWidth + margin.left],
          domain: extent(data, getDate) as [Date, Date]
        }),
      [innerWidth, margin.left]
    );

    const stockValueScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top, margin.top],
          domain: [0, (max(data, getStockValue) || 0) + 2],
          round: true,
          nice: true
        }),
      [margin.top, innerHeight]
    );

    const handleTooltip = useCallback(
      (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { x } = localPoint(event) || { x: 0 };
        const x0 = dateScale.invert(x - (!hideLeftAxis ? AXIS_WIDTH : 0));
        const index = bisectDate(data, x0, 1);
        const d0 = data[index - 1];
        const d1 = data[index];
        let d = d0;
        if (d1 && getDate(d1)) {
          d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
        }
        showTooltip({
          tooltipData: d,
          tooltipLeft: x - (!hideLeftAxis ? AXIS_WIDTH : 0),
          tooltipTop: stockValueScale(getStockValue(d))
        });
      },
      [showTooltip, stockValueScale, dateScale]
    );

    return (
      <div>
        <svg height={height + (!hideBottomAxis ? AXIS_HEIGHT + AXIS_WIDTH : 0)} width={width}>
          <Group left={!hideLeftAxis ? AXIS_WIDTH : 0} top={!hideBottomAxis ? AXIS_HEIGHT : 0}>
            <Group width={innerWidth}>
              <LinearGradient
                from={accentColor}
                id="area-gradient"
                to={background}
                toOpacity={0.1}
              />
              <GridRows
                left={margin.left}
                pointerEvents="none"
                scale={stockValueScale}
                stroke={columsColor}
                strokeDasharray="4"
                strokeOpacity={1}
                strokeWidth={1}
                width={innerWidth}
              />
              <AreaClosed<AppleStock>
                curve={curveMonotoneX}
                data={data}
                fill="url(#area-gradient)"
                stroke={accentColor}
                strokeWidth={0}
                width={innerWidth}
                x={(d) => dateScale(getDate(d)) ?? 0}
                y={(d) => stockValueScale(getStockValue(d)) ?? 0}
                yScale={stockValueScale}
              />
              {data.length === 1 &&
                data.map((d, i) => (
                  <circle
                    cx={dateScale(getDate(d))}
                    cy={stockValueScale(getStockValue(d))}
                    fill={accentColor}
                    key={`dot-${i}`}
                    r={3}
                  />
                ))}
            </Group>

            <Bar
              fill="transparent"
              height={innerHeight}
              rx={14}
              width={innerWidth}
              x={margin.left}
              y={margin.top}
              onMouseLeave={() => hideTooltip()}
              onMouseMove={handleTooltip}
              onTouchMove={handleTooltip}
              onTouchStart={handleTooltip}
            />

            {!hideBottomAxis && (
              <AxisBottom
                numTicks={width > 520 ? 10 : 5}
                scale={dateScale}
                stroke={axisColor}
                tickLabelProps={axisBottomTickLabelProps}
                tickStroke={axisColor}
                top={innerHeight + margin.top}
              />
            )}
            {!hideLeftAxis && (
              <AxisLeft
                numTicks={5}
                scale={stockValueScale}
                stroke={axisColor}
                tickLabelProps={axisLeftTickLabelProps}
                tickStroke={axisColor}
              />
            )}

            {tooltipData && (
              <g>
                <Line
                  from={{ x: tooltipLeft, y: margin.top }}
                  pointerEvents="none"
                  stroke={accentColorDark}
                  strokeDasharray="5,2"
                  strokeWidth={2}
                  to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                />
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop + 1}
                  fill="black"
                  fillOpacity={0.1}
                  pointerEvents="none"
                  r={4}
                  stroke="black"
                  strokeOpacity={0.1}
                  strokeWidth={2}
                />
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop}
                  fill={tooltipBackground}
                  pointerEvents="none"
                  r={4}
                  stroke="white"
                  strokeWidth={2}
                />
              </g>
            )}
          </Group>
        </svg>
        {tooltipData && (
          <div>
            <TooltipWithBounds
              key={Math.random()}
              left={tooltipLeft - 12}
              style={tooltipStyles}
              top={tooltipTop - 12}
            >
              {`${getStockValue(tooltipData)}`}
            </TooltipWithBounds>
            <Tooltip
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                minWidth: 72,
                textAlign: 'center',
                transform: 'translateX(-50%)'
              }}
              top={innerHeight + margin.top - 14}
            >
              {formatDate(getDate(tooltipData))}
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
);
