import React from 'react';
import PropTypes from 'prop-types';

const IconStatMobile = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 13 24"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3986 21.1698H1.0965C0.874418 21.1698 0.695312 20.9907 0.695312 20.7686V3.22345C0.695312 3.00137 0.874418 2.82227 1.0965 2.82227H11.3915C11.6135 2.82227 11.7927 3.00137 11.7927 3.22345V20.7686C11.7927 20.9907 11.6135 21.1698 11.3986 21.1698Z"
      fill="#05051D"
      opacity="0.2"
    />
    <path
      clipRule="evenodd"
      d="M1.59757 24H10.8967C11.7779 24 12.4943 23.2836 12.4943 22.3953V1.59757C12.4943 0.716421 11.7779 0 10.8967 0H1.59757C0.716421 0 0 0.716421 0 1.59757V22.4024C0 23.2836 0.716421 24 1.59757 24ZM1.0965 21.1703H11.3986C11.6135 21.1703 11.7927 20.9912 11.7927 20.7691V3.22394C11.7927 3.00186 11.6135 2.82275 11.3915 2.82275H1.0965C0.874418 2.82275 0.695312 3.00186 0.695312 3.22394V20.7691C0.695312 20.9912 0.874418 21.1703 1.0965 21.1703ZM6.24404 23.3567C5.70545 23.3567 5.26904 22.9203 5.26904 22.3817C5.26904 21.8431 5.70545 21.4067 6.24404 21.4067C6.78264 21.4067 7.21904 21.8431 7.21904 22.3817C7.21904 22.9203 6.78264 23.3567 6.24404 23.3567ZM4.8422 1.43H7.64856C7.88402 1.43 7.99464 1.57989 7.99414 1.765C7.99414 1.95011 7.88402 2.1 7.64856 2.1H4.8422C4.60675 2.1 4.49414 1.95011 4.49414 1.765C4.49414 1.57989 4.60675 1.43 4.8422 1.43Z"
      fill="#05051D"
      fillRule="evenodd"
    />
  </svg>
);

IconStatMobile.propTypes = {
  className: PropTypes.string
};

IconStatMobile.defaultProps = {
  className: ''
};

export default IconStatMobile;
