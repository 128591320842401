type uiLocale = {
  id: string;
  name: string;
};

export enum UserRole {
  ADMIN = 'admin',
  MEMBER = 'member'
}

export enum AppPlan {
  FREE = 'Free',
  INDIE = 'Pro',
  STARTUP = 'All-in',
  MAU = 'MAU'
}

export enum UserSubscriptionStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  PAST_DUE = 'past_due',
  UNPAID = 'unpaid',
  TRIALING = 'trialing',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired'
}

export interface State {
  uiLocales: uiLocale[];
  status: 'idle' | 'loading' | 'loaded';
  user: {
    id: string | null;
    name: string | null;
    email: string | null;
    isEmailConfirmed: boolean;
    locale: string;
    role: UserRole;
    mauLimit: number;
    mauUsage: number;
    priceId: string;
    subscriptionId: string;
    subscriptionStart: number | null;
    subscriptionFinish: number | null;
    subscriptionStatus: UserSubscriptionStatus;
  };
}
