import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { StoriesGroupBase, fetchGroupsAnalytic, useGetActionByAccess } from '@features';
import { RootState, useAppDispatch } from '@store';
import { useHistory, useParams } from 'react-router-dom';
import { AppFeatures } from '@features/user/consts';
import { useTranslation } from 'react-i18next';
import { API } from '@services';
import { DateTime } from 'luxon';
import { AppleStock } from '@visx/mock-data/lib/mocks/appleStock';
import { shortEnglishHumanizer } from '@utils';
import { FreshTable, FreshTableDataRow } from '@components/_fresh';
import { LineChart } from '@components/LineChart';
import { useIsMobile } from '@hooks';
import { AnalyticsHeader } from '../AnalyticsHeader';
import { AnalyticsIndicator } from '../AnalyticsIndicator';
import { AnalyticsDevicesStat } from '../AnalyticsDevicesStat';
import { AnalyticsOsStat } from '../AnalyticsOsStat';
import { AnalyticsCountriesStat } from '../AnalyticsCountriesStat';
import './AnalyticsStoryGroupsTab.scss';

const b = block('AnalyticsStoryGroupsTab');

export const AnalyticsStoryGroupsTab = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { appId } = useParams<{ appId: string }>();
  const groups = useSelector((store: RootState) => store.groupsAnalytic.data);
  const status = useSelector((store: RootState) => store.groupsAnalytic.status);
  const range = useSelector((store: RootState) => store.storiesAnalyticsGroup.range);
  const appsState = useSelector((store: RootState) => store.appManager);
  const onGroupClick = useGetActionByAccess();
  const isMobile = useIsMobile();
  const [groupRows, setGroupRows] = useState<FreshTableDataRow[] | null>(null);

  const getGroupRow = (item: StoriesGroupBase): FreshTableDataRow => {
    const watchDuration = item.statistic?.duration
      ? shortEnglishHumanizer(item.statistic.duration * 1000)
      : '0s';

    return {
      title: (
        <div
          className={b('tableCol', { first: true, link: true })}
          onClick={() =>
            onGroupClick(AppFeatures.EXTENDED_ANALITICS, () => {
              history.push(`/dashboard/${appId}/analytics/${item.id}/stories`);
            })
          }
        >
          <img className={b('tableGroupImg')} src={item.image} />
          <p className={b('tableText')}>{item.title}</p>
        </div>
      ),
      open: item.statistic?.open || 0,
      impression: item.statistic?.impression || 0,
      duration: watchDuration,
      clicks: item.statistic?.click || 0
    };
  };

  const [activeUsers, setActiveUsers] = useState<AppleStock[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');

  const [indicatorsData, setIndicatorsData] = useState<
    { title: string; value: number; id: string }[]
  >([]);

  useEffect(() => {
    const getRangeFrom = () => {
      let rangeFrom = DateTime.now().startOf('month').toISODate();

      if (range.from) {
        rangeFrom = DateTime.fromSeconds(range.from).toISODate();
      }

      return rangeFrom ?? '';
    };

    const getRangeTo = () => {
      let rangeTo = DateTime.now().endOf('month').toISODate();

      if (range.to) {
        rangeTo = DateTime.fromSeconds(range.to).toISODate();
      }

      return rangeTo ?? '';
    };

    setDateFrom(getRangeFrom());
    setDateTo(getRangeTo());
  }, [range]);

  useEffect(() => {
    const fetchUsersData = async () => {
      setIsLoading(true);

      const { data } = await API.analytics.getUsers({
        appId,
        interval: 'day',
        dateFrom,
        dateTo
      });

      if (data.data && !data.error) {
        setActiveUsers(
          data.data.map((item: any) => ({
            date: item.date,
            close: item.total
          }))
        );
      } else {
        setActiveUsers([]);
      }

      setIsLoading(false);
    };

    if (dateFrom && dateTo) {
      fetchUsersData();
    }
  }, [dateFrom, dateTo, appId]);

  useEffect(() => {
    if (groups && status === 'loaded') {
      setGroupRows(groups.map((group) => getGroupRow(group)));
    } else {
      setGroupRows([]);
    }
  }, [groups]);

  const [chartInnerWidth, setChartInnerWidth] = useState(0);

  const chartInnerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (chartInnerRef.current) {
        setChartInnerWidth(chartInnerRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (appId && appsState.status === 'loaded') {
      dispatch(fetchGroupsAnalytic({ appId, range }));
    }
  }, [appId, range, dispatch, appsState]);

  const headerCols = [
    {
      title: t('dashboard.analytics.groups.title'),
      key: 'title'
    },
    {
      title: t('dashboard.analytics.groups.open'),
      key: 'open'
    },
    {
      title: t('dashboard.analytics.groups.impression'),
      key: 'impression'
    },
    {
      title: t('dashboard.analytics.groups.duration'),
      key: 'duration'
    },
    {
      title: t('dashboard.analytics.groups.clicks'),
      key: 'clicks'
    }
  ];

  useEffect(() => {
    const data = [
      {
        id: 'activeUsers',
        title: t('dashboard.analytics.activeUsers'),
        value: activeUsers.reduce((acc, item) => acc + item.close, 0)
      },
      {
        id: 'views',
        title: t('dashboard.analytics.groups.views'),
        value: groupRows?.reduce((acc, item) => acc + (Number(item.open) || 0), 0) || 0
      },
      {
        id: 'impression',
        title: t('dashboard.analytics.groups.impression'),
        value: groupRows?.reduce((acc, item) => acc + (Number(item.impression) || 0), 0) || 0
      },
      {
        id: 'clicks',
        title: t('dashboard.analytics.groups.clicks'),
        value: groupRows?.reduce((acc, item) => acc + (Number(item.clicks) || 0), 0) || 0
      }
    ];

    setIndicatorsData(data);
  }, [activeUsers, groupRows]);

  return (
    <div className={b()}>
      <AnalyticsHeader />
      <div className={b('indicators')}>
        {indicatorsData.map((item) => (
          <AnalyticsIndicator
            id={item.id}
            isLoading={isLoading || status === 'pending'}
            key={item.title}
            title={item.title}
            tooltipText={
              item.id === 'impression' ? t('dashboard.analytics.groups.impressionTooltip') : ''
            }
            value={item.value}
          />
        ))}
      </div>
      <div className={b('chart')}>
        <div className={b('title')}>{t('dashboard.analytics.activeUsers')}</div>
        <div className={b('chartContainer')} ref={chartInnerRef}>
          <LineChart
            data={activeUsers}
            height={isMobile ? 200 : 400}
            isLoading={isLoading || status === 'pending'}
            width={chartInnerWidth}
          />
        </div>
      </div>
      <div className={b('targetStats')}>
        <div className={b('targetStatsIndicators')}>
          <div className={b('targetStatsIndicator')}>
            <AnalyticsDevicesStat appId={appId} dateFrom={dateFrom} dateTo={dateTo} />
          </div>
          <div className={b('targetStatsIndicator')}>
            <AnalyticsOsStat appId={appId} dateFrom={dateFrom} dateTo={dateTo} />
          </div>
        </div>
        <div className={b('targetStatsItem')}>
          <div className={b('title')}>{t('dashboard.analytics.countries')}</div>
          <AnalyticsCountriesStat appId={appId} dateFrom={dateFrom} dateTo={dateTo} />
        </div>
      </div>
      <FreshTable
        dataRows={groupRows ?? []}
        emptyText={t('dashboard.analytics.groups.noData')}
        headerCols={headerCols}
        isLoading={status === 'pending'}
      />
    </div>
  );
};
