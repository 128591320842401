import React, { useEffect, useState } from 'react';
import { API } from '@services';
import { useTranslation } from 'react-i18next';
import { IconStatDesktop, IconStatMobile, IconStatTablet } from '@components';
import { AnalyticsPercentageTable } from '../AnalyticsPercentageTable';
import './AnalyticsDevicesStat.scss';

interface AnalyticsDevicesStatProps {
  appId: string;
  dateFrom: string;
  dateTo: string;
}

enum DeviceType {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
  TABLET = 'tablet'
}

interface DeviceStat {
  icon: JSX.Element;
  title: string;
  value: number;
}

export const AnalyticsDevicesStat: React.FC<AnalyticsDevicesStatProps> = ({
  appId,
  dateFrom,
  dateTo
}) => {
  const [deviceStats, setDeviceStats] = useState<DeviceStat[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const deviceTranslation = {
    mobile: t('dashboard.analytics.mobile'),
    desktop: t('dashboard.analytics.desktop'),
    tablet: t('dashboard.analytics.tablet')
  };

  const getDeviceType = (device: string) => {
    if (device.includes('iphone') || device.includes('android') || device.includes('moble')) {
      return DeviceType.MOBILE;
    }

    if (device.includes('ipad') || device.includes('tablet')) {
      return DeviceType.TABLET;
    }

    return DeviceType.DESKTOP;
  };

  const getDeviceIcon = (device: DeviceType) => {
    switch (device) {
      case DeviceType.MOBILE:
        return <IconStatMobile />;
      case DeviceType.TABLET:
        return <IconStatTablet />;
      case DeviceType.DESKTOP:
        return <IconStatDesktop />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setDeviceStats([]);

      const { data } = await API.analytics.getReport({
        appId,
        unit: 'device',
        dateFrom,
        dateTo
      });

      if (data.data && !data.error) {
        const groupedData = data.data?.reduce((acc: any, item: any) => {
          const deviceType = getDeviceType(item.value);
          if (!acc[deviceType]) {
            acc[deviceType] = { total: 0, type: deviceType };
          }
          acc[deviceType].total += item.total;
          return acc;
        }, {});

        setDeviceStats(
          Object.values(groupedData).map((item: any) => ({
            icon: getDeviceIcon(item.type) as React.ReactElement,
            title: deviceTranslation[item.type as DeviceType],
            value: item.total
          })) ?? []
        );
      }

      setIsLoading(false);
    };

    if (dateFrom && dateTo) {
      fetchData();
    }
  }, [dateFrom, dateTo, appId]);

  useEffect(() => {
    if (!deviceStats?.length && !isLoading) {
      setDeviceStats([
        {
          icon: <IconStatMobile />,
          title: deviceTranslation[DeviceType.MOBILE],
          value: 0
        },
        {
          icon: <IconStatTablet />,
          title: deviceTranslation[DeviceType.TABLET],
          value: 0
        },
        {
          icon: <IconStatDesktop />,
          title: deviceTranslation[DeviceType.DESKTOP],
          value: 0
        }
      ]);
    }
  }, [deviceStats, isLoading]);

  return (
    <AnalyticsPercentageTable
      isLoading={isLoading}
      items={deviceStats}
      title={t('dashboard.analytics.devices')}
    />
  );
};
