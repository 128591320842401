import React, { useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { RootState, useAppDispatch } from '@store';
import { DateTime } from 'luxon';
import Skeleton from 'react-loading-skeleton';
import { ScheduleTimeType, Story, StoryLayer, StoryStatus } from '@features/stories/types';
import { useTranslation } from 'react-i18next';
import {
  useGetCurrentGroup,
  useIsDefaultLanguageChoosen,
  useSetBodyOverflow
} from '@features/stories/hooks';
import { useGroupPublish } from '@features/groups/hooks';
import { GroupsType } from '@features/groups';
import {
  StoryCardMessage,
  StoryLimitedWidgets,
  StoryPreview,
  StoryScheduleModal,
  StoryShareModal
} from '@features/stories/components';
import { AppFeatures } from '@features/user/consts';
import { nanoid } from 'nanoid';
import { MOCK_STORY_STYLE, STORY_HEIGHT, STORY_WIDTH } from '@features/consts';
import { useGetActionByAccess } from '@features/user';
import { getInteractiveWidgetIds } from '@features/stories/utils';
import { AppPlan } from '@features/user/types';
import { MessageTypes, informSlice } from '@features/inform';
import { BackgroundColorType, StoryModal } from '@storysdk/react';
import { AppCollaborationFeatures, useGetActionByCollaboratorRole } from '@features';
import { Icon } from '@components';
import { useGetUserSubscription, useIsMobile } from '@hooks';
import { getActiveStoriesFlattenedArr } from '@utils';
import AddStory from '../AddStory/AddStory';
import {
  fetchCreateStory,
  fetchRemoveLayersGroup,
  fetchUpdateLayersGroupSchedule,
  fetchUpdateLayersGroupStatus
} from '../../storiesSlice';
import { StoryRenderingFallback } from '../../components/StoryRenderingFallback/StoryRenderingFallback';
import './DashboardStories.scss';

const b = block('DashboardStories');

type PropsType = {
  type: GroupsType;
  isLoading?: boolean;
};

export const DashboardStories: React.FC<PropsType> = ({ isLoading, type }) => {
  const { appId, groupId } = useParams<{ appId: string; groupId: string }>();

  const dispatch = useAppDispatch();
  const app = useSelector((store: RootState) => store.appManager);
  const stories = useSelector((store: RootState) => store.stories.stories);
  const storiesStatus = useSelector((store: RootState) => store.stories.status);
  const filter = useSelector((state: RootState) => state.stories.filter);
  const currentGroup = useGetCurrentGroup(type, groupId);
  const onDefaultLocale = useIsDefaultLanguageChoosen();
  const [storyToSchedule, setStoryToSchedule] = useState<Story | null>(null);
  const [publishedStoryId, setPublishedStoryId] = useState<{
    storyId: string;
    layerId: string;
  } | null>(null);
  const [shareStoryId, setShareStoryId] = useState<{
    storyId: string;
    layerId: string;
  } | null>(null);

  useGroupPublish(appId, groupId, type, storiesStatus === 'loaded');
  const [previewStoryId, setPreviewStoryId] = useState<string | null>(null);

  const defaultLocale = useSelector(
    (store: RootState) => store.appManager.current?.appLocalization.default
  );
  const currentLocale =
    useSelector((store: RootState) => store.appManager.currentLocale) ?? defaultLocale;

  const storiesArr = useMemo(
    () =>
      getActiveStoriesFlattenedArr(stories)
        .filter((story) => {
          if (!story.storyData[currentLocale]) {
            return false;
          }

          if (filter === 'all') {
            return true;
          }

          if (filter === 'haveUnpublishedChanges') {
            return story.isHasUnpublishedChanges;
          }

          if (app.currentLocale) {
            return story.storyData[app.currentLocale].status === filter;
          }

          return false;
        })
        .sort((storyA, storyB) => {
          const statusA = storyA.storyData[currentLocale].status;
          const statusB = storyB.storyData[currentLocale].status;
          const positionA = storyA.position;
          const positionB = storyB.position;

          if (statusA === StoryStatus.ACTIVE && statusB !== StoryStatus.ACTIVE) {
            return -1;
          }
          if (statusA !== StoryStatus.ACTIVE && statusB === StoryStatus.ACTIVE) {
            return 1;
          }
          if (positionA < positionB) {
            return -1;
          }
          if (positionA > positionB) {
            return 1;
          }
          return 0;
        }),
    [app.currentLocale, currentLocale, filter, stories]
  );

  const onActionClick = useGetActionByAccess();
  const onActionClickCollaborator = useGetActionByCollaboratorRole();
  const subscription = useGetUserSubscription();
  const isMobile = useIsMobile();

  const isInteractiveWidgetsAvailable = true;

  let lastLayersGroupPosition = 0;

  Object.values(stories).forEach((story: StoryLayer) => {
    if (story.position > lastLayersGroupPosition) {
      lastLayersGroupPosition = story.position;
    }
  });

  const { t } = useTranslation();

  const handleRemove = (layersGroupId: string) => () => {
    dispatch(
      fetchRemoveLayersGroup({
        appId,
        groupId,
        layersGroupId
      })
    );
  };

  const handlePublish = (layersGroupId: string, story: Story) => () => {
    dispatch(
      fetchUpdateLayersGroupStatus({
        appId,
        groupId,
        layersGroupId,
        status: StoryStatus.ACTIVE,
        noNotification: true
      })
    ).then(() => {
      setPublishedStoryId({
        storyId: story.id,
        layerId: story.layerData.layersGroupId
      });
    });
  };

  const handleUnpublish = (layersGroupId: string) => () => {
    dispatch(
      fetchUpdateLayersGroupStatus({ appId, groupId, layersGroupId, status: StoryStatus.DRAFT })
    );
  };

  const handleCopyLayersGroup = (story: Story) => {
    const layerData = {
      layersGroupId: nanoid(),
      score: {
        letter: 'A',
        points: 0
      }
    };

    for (const layerId in stories[story.layerData.layersGroupId].layers) {
      if (stories[story.layerData.layersGroupId].layers[layerId]) {
        dispatch(
          fetchCreateStory({
            appId,
            groupId,
            type,
            story: {
              ...stories[story.layerData.layersGroupId].layers[layerId],
              widgets: stories[story.layerData.layersGroupId].layers[layerId].widgets.map(
                (widget) => ({
                  ...widget,
                  id: `W${nanoid()}`
                })
              ),
              layerData: {
                ...layerData,
                isDefaultLayer:
                  stories[story.layerData.layersGroupId].layers[layerId].layerData.isDefaultLayer,
                positionInGroup:
                  stories[story.layerData.layersGroupId].layers[layerId].layerData.positionInGroup,
                isWidgetsSizeSyncActive:
                  stories[story.layerData.layersGroupId].layers[layerId].layerData
                    .isWidgetsSizeSyncActive
              },
              position: lastLayersGroupPosition + 1
            }
          })
        );
      }
    }
  };

  const handleSaveSchedule = (layersGroupId: string, time: ScheduleTimeType) => {
    dispatch(
      fetchUpdateLayersGroupSchedule({
        appId,
        groupId,
        layersGroupId,
        time
      })
    );

    setStoryToSchedule(null);
  };

  const openShareModal = ({ storyId, layerId }: { storyId: string; layerId: string }) => {
    setShareStoryId({
      storyId,
      layerId
    });
  };

  useEffect(() => {
    const storyId = publishedStoryId?.storyId || '';
    const layerId = publishedStoryId?.layerId || '';

    if (storyId && layerId) {
      const selectedLayer = layerId ? stories[layerId].layers[storyId] : null;

      if (selectedLayer?.layerData.shortDataId) {
        const shareLink = `${process.env.REACT_APP_SITE_URL}/share/${selectedLayer?.layerData.shortDataId}`;

        dispatch(
          informSlice.actions.addMessage({
            type: MessageTypes.SHARE,
            shareLink
          })
        );

        setPublishedStoryId(null);
        setShareStoryId(null);
      }
    }
  }, [dispatch, publishedStoryId, shareStoryId, stories]);

  const handleClosePreviewModal = () => {
    setPreviewStoryId(null);
  };

  useSetBodyOverflow(!!previewStoryId);

  const getStoryStatusLabel = (status: StoryStatus) => {
    if (status === StoryStatus.ACTIVE) {
      return t('dashboard.stories.published');
    }

    if (status === StoryStatus.UNPUBLISHED) {
      return t('dashboard.stories.unpublished');
    }

    return t('dashboard.stories.draft');
  };

  if (isLoading) {
    return (
      <div className={b()}>
        {Array.from({ length: 4 }).map((_, index) => (
          <div className={b('storyItem')} key={`storyMock-${index}`}>
            <Skeleton
              height={MOCK_STORY_STYLE.height}
              style={{ borderRadius: MOCK_STORY_STYLE.borderRadius }}
              width={MOCK_STORY_STYLE.width}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={b()}>
      {type === GroupsType.GROUP && (
        <div className={b('storyItem')}>
          <div className={b('story', { add: onDefaultLocale })}>
            {onDefaultLocale ? (
              <AddStory type={type} />
            ) : (
              <StoryCardMessage text={t('editor.noCreateStories')} theme="light" />
            )}
          </div>
        </div>
      )}

      {!!app.currentLocale &&
        storiesArr.map((story) => {
          const interactiveWidgetIds = getInteractiveWidgetIds(story.widgets);
          return (
            <div className={b('storyItem')} key={story.id}>
              <div className={b('story')}>
                <ErrorBoundary FallbackComponent={StoryRenderingFallback}>
                  <StoryPreview
                    dashboardVariant
                    isOnboarding={type === GroupsType.ONBOARDING}
                    story={story}
                  />
                </ErrorBoundary>

                <div className={b('overlay')}>
                  {!isInteractiveWidgetsAvailable &&
                    interactiveWidgetIds.length > 0 &&
                    subscription.plan !== AppPlan.FREE ? (
                    <StoryLimitedWidgets
                      appId={appId}
                      groupId={groupId}
                      interactiveWidgetIds={interactiveWidgetIds}
                      story={story}
                      type={type}
                    />
                  ) : (
                    <>
                      <div className={b('mainButtons')}>
                        {!isMobile && (
                          <Link
                            className={b('btn')}
                            to={{
                              pathname: `/editor/${appId}/${type}/${groupId}`,
                              state: {
                                storyId: story.id,
                                layersGroupId: story.layerData.layersGroupId
                              }
                            }}
                          >
                            <Icon className={b('btn-icon').toString()} name="edit-fresh" />
                            {t('dashboard.stories.openInEditor')}
                          </Link>
                        )}
                        {(story.storyData[currentLocale].status === StoryStatus.DRAFT ||
                          story.isHasUnpublishedChanges ||
                          (story.storyData[currentLocale].status === StoryStatus.UNPUBLISHED &&
                            (!subscription.mauLimit ||
                              (subscription.mauLimit &&
                                subscription.mauUsage < subscription.mauLimit)))) &&
                          story.background.type !== BackgroundColorType.TRANSPARENT && (
                            <button
                              className={b('btn')}
                              onClick={() =>
                                onActionClickCollaborator(
                                  AppCollaborationFeatures.PUBLISH_STORIES,
                                  () => {
                                    onActionClick(
                                      AppFeatures.PUBLISH_STORIES,
                                      handlePublish(story.layerData.layersGroupId, story)
                                    );
                                  }
                                )
                              }
                            >
                              <Icon className={b('btn-icon').toString()} name="publish" />
                              {t('dashboard.stories.publish')}
                            </button>
                          )}

                        {story.storyData[currentLocale].status === StoryStatus.ACTIVE && (
                          <button
                            className={b('btn')}
                            onClick={() =>
                              onActionClickCollaborator(
                                AppCollaborationFeatures.PUBLISH_STORIES,
                                handleUnpublish(story.layerData.layersGroupId)
                              )
                            }
                          >
                            <Icon className={b('btn-icon').toString()} name="archive" />
                            {t('dashboard.stories.removeToDraft')}
                          </button>
                        )}
                      </div>
                      <div className={b('toolBar')}>
                        <button
                          className={b('toolbarBtn')}
                          onClick={() => setPreviewStoryId(story.id)}
                        >
                          <Icon
                            className={b('toolbarBtnIcon', {
                              white: true,
                              large: true
                            }).toString()}
                            name="play"
                          />
                        </button>

                        {story?.storyData[currentLocale]?.status === StoryStatus.ACTIVE && (
                          <button
                            className={b('toolbarBtn')}
                            onClick={() =>
                              openShareModal({
                                storyId: story.id,
                                layerId: story.layerData.layersGroupId
                              })
                            }
                          >
                            <Icon className={b('toolbarBtnIcon').toString()} name="share" />
                          </button>
                        )}

                        {/* <button
                          className={b('toolbarBtn')}
                          onClick={() => openScheduleModal(story)}
                        >
                          <Icon
                            className={b('toolbarBtnIcon', { white: true }).toString()}
                            name="calendar-fresh"
                          />
                        </button> */}

                        {onDefaultLocale && (
                          <>
                            {filter === StoryStatus.DRAFT && (
                              <button
                                className={b('toolbarBtn')}
                                onClick={() =>
                                  onActionClickCollaborator(
                                    AppCollaborationFeatures.CREATE_STORIES,
                                    () => {
                                      onActionClick(AppFeatures.CREATE_STORIES, () =>
                                        handleCopyLayersGroup(story)
                                      );
                                    }
                                  )
                                }
                              >
                                <Icon
                                  className={b('toolbarBtnIcon', { white: true }).toString()}
                                  name="copy"
                                />
                              </button>
                            )}
                            <button
                              className={b('toolbarBtn')}
                              onClick={() =>
                                onActionClickCollaborator(
                                  AppCollaborationFeatures.REMOVE_STORIES,
                                  handleRemove(story.layerData.layersGroupId)
                                )
                              }
                            >
                              <Icon
                                className={b('toolbarBtnIcon', {
                                  large: true
                                }).toString()}
                                name="delete-fresh"
                              />
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>

                <div className={b('storyHead')}>
                  <div className={b('storyStatus')}>
                    {getStoryStatusLabel(story.storyData[currentLocale].status)}
                  </div>
                  {story.isHasUnpublishedChanges && (
                    <div className={b('storyChangesLabel')}>
                      {t('dashboard.stories.youHaveUnpublishedChanges')}
                    </div>
                  )}
                </div>
              </div>

              <div className={b('storyParams')}>
                <div className={b('storyParamsItem')}>
                  {DateTime.fromISO(story.createdAt)
                    .toLocaleString({
                      day: 'numeric',
                      month: 'numeric',
                      year: '2-digit'
                    })
                    .replaceAll('/', '.')}
                </div>
              </div>
            </div>
          );
        })}

      {type === GroupsType.ONBOARDING && (
        <div className={b('storyItem')}>
          <div className={b('story', { add: onDefaultLocale })}>
            {onDefaultLocale ? (
              <AddStory type={type} />
            ) : (
              <StoryCardMessage text={t('editor.noCreateStories')} theme="light" />
            )}
          </div>
        </div>
      )}

      {!!storyToSchedule && (
        <StoryScheduleModal
          endTime={storyToSchedule.storyData[currentLocale].end_time}
          handleSaveSchedule={handleSaveSchedule}
          isOpen
          layersGroupId={storyToSchedule.layerData.layersGroupId}
          startTime={storyToSchedule.storyData[currentLocale].start_time ?? ''}
          onClose={() => setStoryToSchedule(null)}
        />
      )}

      {shareStoryId && (
        <StoryShareModal
          groupId={groupId}
          isOpen
          layerId={publishedStoryId?.layerId || shareStoryId?.layerId || ''}
          storyId={publishedStoryId?.storyId || shareStoryId?.storyId || ''}
          type={type}
          onClose={() => {
            setPublishedStoryId(null);
            setShareStoryId(null);
          }}
        />
      )}

      {!!previewStoryId && (
        <StoryModal
          currentGroup={{
            id: currentGroup.id,
            imageUrl: currentGroup.image,
            title: currentGroup.title,
            type: currentGroup.type,
            settings: currentGroup.settings,
            stories: []
          }}
          isCacheDisabled
          isEditorMode
          isFirstGroup
          isForceCloseAvailable
          isLastGroup
          isShowMockup={app.current?.settings?.isShowMockup}
          isShowing
          isStatusBarActive
          startStoryId={previewStoryId ?? undefined}
          stories={storiesArr.map((item, index) => ({
            ...item,
            storyData: item.widgets.map((widgetItem) => {
              const positionByResolutions =
                widgetItem.positionByResolutions[`${STORY_WIDTH}x${STORY_HEIGHT}`];

              return {
                ...widgetItem,
                position: positionByResolutions
              };
            }),
            position: index,
            positionIndex: index
          }))}
          storyHeight={STORY_HEIGHT}
          storyWidth={STORY_WIDTH}
          onClose={handleClosePreviewModal}
          onNextGroup={() => undefined}
          onPrevGroup={() => undefined}
        />
      )}
    </div>
  );
};
