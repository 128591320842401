import React, { useCallback, useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import ReactSlider from 'react-slider';
import { API } from '@services';
import { useAppDispatch } from '@store';
import { fetchCancelSubscription } from '@features';
import { formatNumber, getCurrencySymbol } from '@utils';
import { FreshButton } from '@components/_fresh';
import './PriceMauCard.scss';

const b = block('PriceMauCard');

interface Tier {
  upFrom?: number;
  upTo: number;
  price: number;
}

interface PriceMauCardProps {
  currentPlan: {
    mauLimit: number;
    currency: string;
    subscriptionId?: string;
  };
  priceId: string;
  tiers: Tier[];
}

export const PriceMauCard: React.FC<PriceMauCardProps> = ({ priceId, currentPlan, tiers }) => {
  const { t } = useTranslation();
  const tiersSorted = useMemo(() => tiers.sort((a, b) => a.price - b.price), [tiers]);
  const [selectedTier, setSelectedTier] = useState<Tier>(tiersSorted[0]);
  const availableValues = useMemo(() => tiersSorted.map((tier) => tier.upTo), [tiersSorted]);
  const [selectedValue, setSelectedValue] = useState<number>(1);
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentPlan.mauLimit === 0) {
      setSelectedValue(availableValues.length);
      return;
    }

    const currentTierIndex = tiersSorted.findIndex((tier) => tier.upTo === currentPlan.mauLimit);
    setSelectedValue(currentTierIndex + 1);
  }, [currentPlan, availableValues]);

  const Thumb = useCallback((props: any) => <div {...props} className={b('thumb')} />, []);
  const SliderTrack = useCallback(
    (props: any, state: any) => (
      <div {...props} className={b('track', { selected: state.index === 1 })} />
    ),
    []
  );

  const handleChange = (newValue: any) => {
    if (newValue === 0) {
      setSelectedValue(1);
      return;
    }

    setSelectedValue(newValue);
  };

  useEffect(() => {
    const tier = tiersSorted[selectedValue - 1];

    setSelectedTier(tier);
  }, [selectedValue, tiersSorted]);

  const btnInfo = useMemo(() => {
    if (selectedTier.upTo === currentPlan.mauLimit) {
      return {
        isCurrent: true,
        text: t('dashboard.billing.currentPlan')
      };
    }

    if (
      (selectedTier.upTo < currentPlan.mauLimit && !selectedTier.upFrom) ||
      currentPlan.mauLimit === 0
    ) {
      return {
        isDowngradable: true,
        text: t('dashboard.billing.downgrade')
      };
    }

    return {
      isUpgradable: true,
      text: t('dashboard.billing.upgrade')
    };
  }, [selectedTier, currentPlan]);

  const handleUpdateClick = async () => {
    if (selectedTier.price === 0 && currentPlan.subscriptionId) {
      dispatch(
        fetchCancelSubscription({
          subscriptionId: currentPlan.subscriptionId
        })
      ).then(() => {
        setIsUpdating(false);
      });

      return;
    }

    const { data } = await API.subscription.checkoutSession({
      priceId,
      limit: selectedTier.upTo
    });
    if (data?.data.url && !data.error) {
      window.open(data.data.url, '_self');
    }
  };

  return (
    <div className={b()}>
      <div className={b('titleContainer')}>
        <p className={b('title')}>
          {t('plans.monthly')} {t('plans.pricing')}
        </p>
        <p className={b('mauLimit')}>
          {selectedTier.upFrom ? '∞' : formatNumber(selectedTier.upTo)} {t('dashboard.usage.mau')}
        </p>
      </div>
      <div className={b('slider')}>
        <ReactSlider
          max={availableValues.length}
          min={0}
          renderThumb={Thumb}
          renderTrack={SliderTrack}
          value={[selectedValue]}
          onChange={handleChange}
        />
      </div>
      <p className={b('price')}>
        {selectedTier.price === 0 ? (
          t('dashboard.billing.free')
        ) : (
          <>
            {selectedTier.price / 100} {getCurrencySymbol(currentPlan.currency)}
          </>
        )}{' '}
        {t('dashboard.billing.upTo')} {selectedTier.upFrom ? '∞' : formatNumber(selectedTier.upTo)}{' '}
        {t('dashboard.billing.monthly')} {t('dashboard.billing.activeUsers')}
      </p>
      <FreshButton
        className={b('btn', {
          disabled: btnInfo.isCurrent || isUpdating
        })}
        color={btnInfo.isCurrent || btnInfo.isDowngradable ? 'transparent-grey-light' : 'pink'}
        disabled={btnInfo.isCurrent}
        text={btnInfo.text}
        onClick={handleUpdateClick}
      />
    </div>
  );
};
