import React, { useMemo } from 'react';
import block from 'bem-cn';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  DashboardStories,
  GroupsType,
  StoriesFilter,
  StoryStatus,
  fetchUpdateAllGroupStoriesStatus
} from '@features';
import { RootState, useAppDispatch } from '@store';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { useFetchStories } from '@features/stories/hooks';
import { useGroup } from '@features/groups/hooks';
import { Icon, IconEditFresh } from '@components';
import { FreshButton } from '@components/_fresh';
import { getActiveStoriesFlattenedArr } from '@utils';
import { useGetUserSubscription } from '@hooks';
import './GroupStoriesTab.scss';

const b = block('GroupStoriesTab');

interface StoriesTabType {
  type: GroupsType;
}

export const GroupStoriesTab: React.FC<StoriesTabType> = ({ type }) => {
  const { appId, groupId } = useParams<{ appId: string; groupId: string }>();
  useFetchStories({ type, appId, groupId });

  const groupsList = useSelector((store: RootState) => store.groups);
  const storiesStatus = useSelector((store: RootState) => store.stories.status);
  const storiesGroup = useGroup(groupId, type);
  const stories = useSelector((store: RootState) => store.stories.stories);
  const currentLocale = useSelector((store: RootState) => store.appManager.currentLocale);
  const subscription = useGetUserSubscription();
  const isChangesExists = useMemo(
    () =>
      currentLocale
        ? getActiveStoriesFlattenedArr(stories).find(
          (story) =>
            (story.isHasUnpublishedChanges &&
              (story.storyData[currentLocale].status === StoryStatus.DRAFT ||
                story.storyData[currentLocale].status === StoryStatus.ACTIVE)) ||
            story.storyData[currentLocale].status === StoryStatus.DRAFT ||
            (story.storyData[currentLocale].status === StoryStatus.UNPUBLISHED &&
              (!subscription.mauLimit ||
                (subscription.mauLimit && subscription.mauUsage < subscription.mauLimit)))
        )
        : false,
    [stories]
  );

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleChangeSatusToAllStories = (status: StoryStatus) => {
    dispatch(
      fetchUpdateAllGroupStoriesStatus({
        appId,
        groupId,
        status
      })
    );
  };

  if (groupsList.status === 'pending' || storiesStatus === 'pending') {
    return (
      <div className={b()}>
        <div className={b('header')}>
          <div className={b('headerContainer')}>
            <Skeleton height={40} style={{ marginRight: 20, borderRadius: 12 }} width={138} />
            <Skeleton height={36} style={{ borderRadius: 12 }} width={84} />
          </div>
        </div>
        <div className={b('carousel')}>
          <DashboardStories isLoading type={type} />
        </div>
      </div>
    );
  }

  if (!storiesGroup) {
    return (
      <div className={b()}>
        <div className={b('carousel')}>
          <div className={b('emptyStory')}>
            <p className={b('emptyStoryTitle')}>{t('dashboard.stories.noGroupText')}</p>
          </div>
        </div>
      </div>
    );
  }

  if (!groupsList.groups) {
    return (
      <div className={b()}>
        <div className={b('carousel')}>
          <div className={b('emptyStory')}>
            <p className={b('emptyStoryTitle')}>{t('dashboard.stories.emptyText')}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('headerContainer')}>
          <p className={b('headerTitle')}>{storiesGroup?.title}</p>
        </div>
        <div className={b('filters')}>
          <StoriesFilter className={b('filtersStories')} type={type} />
          <div className={b('headerBtnContainer')}>
            <FreshButton
              className={b('btnMain').toString()}
              disabled={!isChangesExists}
              leftIcon={<Icon className={b('btnMainIcon').toString()} name="publish" />}
              text={t('dashboard.stories.publishAll')}
              onClick={() => handleChangeSatusToAllStories(StoryStatus.ACTIVE)}
            />

            <Link className={b('btn')} to={`/editor/${appId}/${type}/${groupId}`}>
              <IconEditFresh className={b('btnIcon').toString()} />
              <span>{t('dashboard.stories.openEditor')}</span>
            </Link>
          </div>
        </div>
      </div>
      <div className={b('carousel')}>
        <DashboardStories type={type} />
      </div>
    </div>
  );
};
